import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export const BarChart = ({ ...props }) => {
  const { barChartValue }: any = props;
  const chartLabels =
    barChartValue &&
    barChartValue?.map((entry: any) => (entry.day ? `${entry.day} ${entry?.month}` : entry?.month));

  // Map the converted and lost values for the datasets
  const convertedData: any =
    barChartValue && barChartValue?.map((entry: any) => entry?.converted);
  const lostData: any =
    barChartValue && barChartValue?.map((entry: any) => entry?.lost);
  const state: any = {
    labels: chartLabels,

    datasets: [
      {
        label: "Converted", // First dataset for "Converted"
        backgroundColor: "#4FD1C5", // Green color for "Converted"
        hoverBackgroundColor: "#4FD1C5",
        borderRadius: 7,
        barThickness: 26,
        data: convertedData, // Data for converted values
      },
      {
        label: "Lost", // Second dataset for "Lost"
        backgroundColor: "#F14C52", // Red color for "Lost"
        hoverBackgroundColor: "#F14C52",
        borderRadius: 7,
        barThickness: 26,
        data: lostData, // Data for lost values
      },
    ],
  };

  const option: any = {
    responsive: true,

    plugins: {
      legend: {
        display: true, // Show legend for both datasets
        position: "top", // Position legend at the top
        align: "end", // Align legend to the right
        labels: {
          usePointStyle: true, // Use circle style for legend
          pointStyle: "circle", // Ensure it uses circles
          boxWidth: 10, // Width of the color box
          boxHeight: 10, // Height of the color box
          padding: 20, // Padding between color box and label
          borderRadius: 50, // Padding between the color box and text
          font: {
            size: 12, // Font size for the labels
          },
        },
      },
      title: {
        position: "bottom",
        display: true,
        font: {
          size: 15,
        },

        align: "end", // Align legend to the right
        labels: {
          boxWidth: 2, // Width of the color box in the legend
          padding: 2, // Padding between the color box and text
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },

        ticks: {
          font: {
            size: "11px",
            weight: 500,
            lineHeight: 1.2,
          },
          color: "#404040",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#F3F4F6",
          tickWidth: 0,
          stepType: 100,
          tickMarkLength: 15,
          drawOnArea: false,
          showBorder: false,
          fontSize: 40,
          drawBorder: false,
        },
        border: {
          display: false,
        },

        ticks: {
          stepSize: 0,
          precision: 0,
          font: {
            size: "11px",
            weight: 400,
            lineHeight: 2.6,
          },
          padding: 13,
          color: "#404040",
        },
      },
    },
  };

  return <Bar data={state} options={option} height={props.height || 70} />;
};
