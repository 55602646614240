import React, { useState } from "react";
import { Button, Modal } from "antd";

const AntdModal = ({...props}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    props.setShowPopupModal(false);
  };

  return (
    <Modal
      title={props.title}
      open={props.isModalOpen}
    //   onOk={false}
      footer={false}
      onCancel={handleCancel}
      closable={props.closable}
    >
     {props.children}
    </Modal>
  );
};

export default AntdModal;
