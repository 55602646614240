import { Link, Route, useNavigate } from "react-router-dom";
import { formattedCurrency } from "../helpers/utils";

const Cards = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <div
      className="dash-cards zoom pointer"
      key={props.key}
      onClick={() => props.item.path && navigate(props.item.path)}
    >
      <div>
        <p className="card-name">{props.item.name}</p>
        <p className="card-value">
          {props.item.currency
            ? formattedCurrency(props.item.value)
            : props.item.value ?? 0}
        </p>
      </div>
      <div>
        <img className="card-img" src={props.item.icon} />
      </div>
    </div>
  );
};
export default Cards;
