import { Button, Drawer } from "antd";
import { useState } from "react";
import InputField from "../../Components/InputField";
import DropDownField from "../../Components/dropdown/dropdownField";
import Cancel from "../../Assets/icons/drawerCancel.svg";
import ButtonField from "../../Components/ButtonField";
import DateField from "../../Components/dateField";
import {
  MantotarySymbol,
  Millis,
  NameAndNumberAllowed,
  NumberRex,
  ParseInt,
  ShowError,
  containsAlphabet,
  decimalNumeric,
  isErrorTxt,
  numberOnly,
  removeSpace,
} from "../../helpers/utils";
import dropd from "../../Assets/icons/dropdown.svg";
import { useDiscount } from "./context";
import moment from "moment";

const DiscountDrawer = ({ ...props }) => {
  const {
    actions: { createCoupon },
  } = useDiscount();
  const [open, setOpen] = useState(false);
  const [error, setError]: any = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [initialState, setInitialState]: any = useState({
    campaign: "",
    discount_code: "",
    type: "",
    discount: "",
    duration: "",
    total_time: "",
    end_date: null,
  });
  const discountType = [
    {
      label: "Once",
      value: "once",
    },
    {
      label: "Repeating",
      value: "repeating",
    },
    {
      label: "Forever",
      value: "forever",
    },
  ];
  const { openDrawer, setOpenDrawer } = props;

  const onClose = () => {
    setOpenDrawer(false);
    setInitialState({
      campaign: "",
      discount_code: "",
      type: "",
      discount: "",
      duration: "",
      total_time: "",
      end_date: null,
    });
  };

  const handleInputChange = (e: any, item: any, format?: any) => {
    let data = { ...initialState };
    let val = e.target.value;
    if (item === "campaign") {
      if ((val === "" || NameAndNumberAllowed(val)) && val.length <= 30) {
        data[item] = val;
      }
    } else if (item === "discount_code") {
      if ((val === "" || NameAndNumberAllowed(val)) && val.length <= 8) {
        data[item] = val.toUpperCase();
      }
    } else if (item === "discount") {
      if (decimalNumeric(val) && val < 100) {
        if (val.split(".").length > 2) {
          data[item] = val;
          return;
        }
        if (val.indexOf(".") !== -1) {
          val = val
            .split(".")
            .map((number: string, i: number) => number.slice(0, 2))
            .join(".");
          data[item] = val;
        } else {
          data[item] = val;
        }
        data[item] = val;
      }
    } else if (item === "duration") {
      if (NumberRex(val) && val <= 12) {
        data[item] = val;
      }
    } else if (item === "total_time") {
      if (NumberRex(val) && val.length <= 4) {
        data[item] = val;
      }
    }
    setInitialState(data);
  };
  const HandleDropdown = (item: any) => {
    setInitialState({ ...initialState, type: item, duration: "" });
  };
  const {
    campaign,
    discount_code,
    type,
    discount,
    duration,
    total_time,
    end_date,
  } = initialState;

  const handleSubmit = () => {
    if (isErrorTxt(campaign)) {
      ShowError("Please enter coupon name");
    } else if (isErrorTxt(discount_code)) {
      ShowError("Please enter coupon code");
    } else if (discount_code.length < 4) {
      ShowError("Code must be 4 to 8 characters long");
    } else if (!containsAlphabet(discount_code)) {
      ShowError("Coupon code must have atleast one alphabet");
    } else if (isErrorTxt(type)) {
      ShowError("Please select coupon type");
    } else if (isErrorTxt(discount)) {
      ShowError("Please enter coupon discount");
    } else if (type.value === "repeating" && isErrorTxt(duration)) {
      ShowError("Please enter coupon duration in month");
    } else if (isErrorTxt(total_time)) {
      ShowError("Please enter total times coupon can be redeemed");
    } else if (isErrorTxt(end_date)) {
      ShowError("Please enter coupon end date");
    } else {
      setLoading(true);
      let data: any = {
        name: removeSpace(campaign),
        code: removeSpace(discount_code),
        value: parseFloat(discount),
        duration: type?.value,
        type: "percent",
        expiry: Millis(end_date),
        total_count: ParseInt(total_time),
      };
      if (type?.value === "repeating") {
        data["months"] = ParseInt(duration);
      }
      createCoupon(data, (res) => {
        if (res.success) {
          setLoading(false);
          onClose();
          props.apicall();
        } else {
          setLoading(false);
        }
      });
    }
  };
  const disabledDate = (current: any) => {
    return current && current < moment().add(-1, "days");
  };
  return (
    <>
      <Drawer
        title="Create Discount"
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={openDrawer}
        width={props.width}
        extra={<img src={Cancel} onClick={onClose} className="pointer" />}
      >
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">
            Name of the campaign{MantotarySymbol()}
          </span>
          <div className="text-style">
            <InputField
              placeholder="Name of the campaign"
              lablestyle="labelStyle"
              value={campaign}
              onChange={(e: any) => handleInputChange(e, "campaign")}
            />
          </div>
        </div>
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">
            Discount code{MantotarySymbol()}
          </span>
          <div className="text-style">
            <InputField
              placeholder="Code must be 4 to 8 characters long"
              lablestyle="labelStyle"
              value={discount_code}
              onChange={(e: any) => handleInputChange(e, "discount_code")}
            />
          </div>
        </div>
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">Type{MantotarySymbol()}</span>
          <div className="text-style">
            <DropDownField
              placeholder="Select type"
              value={type}
              data={discountType}
              HandleDropdown={HandleDropdown}
            />
          </div>
        </div>
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">Discount{MantotarySymbol()}</span>
          <div className="text-style discount-field">
            <div className="w-[68%]">
              <input
                type="text"
                placeholder="Discount"
                className="custom-input"
                value={discount}
                onChange={(e: any) => handleInputChange(e, "discount")}
              />
            </div>
            <div className="dicount-per">
              <span>% percentage </span>{" "}
              <img src={dropd} className="ml-[15px] mb-[-3px]" />
            </div>
          </div>
        </div>
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">
            Duration in month{MantotarySymbol()}
          </span>
          <div className="text-style ">
            <InputField
              type="text"
              placeholder="Duration in month"
              lablestyle="labelStyle"
              value={duration}
              disabled={type?.value === "repeating" ? false : true}
              onChange={(e: any) => handleInputChange(e, "duration")}
            />
          </div>
        </div>
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">
            Total times{MantotarySymbol()}
          </span>
          <div className="text-style">
            <InputField
              type="text"
              placeholder="Total times"
              lablestyle="labelStyle"
              value={total_time}
              onChange={(e: any) => handleInputChange(e, "total_time")}
            />
          </div>
        </div>
        <div className="flexCol w-[100%]">
          <span className="cl757575 f14 mb6">End date{MantotarySymbol()}</span>
          <div className="text-style">
            <DateField
              value={end_date}
              onChange={(e: any) =>
                setInitialState({
                  ...initialState,
                  end_date: e,
                })
              }
              disabledDate={disabledDate}
            />
          </div>
        </div>
        <div className="flex justify-end w-[100%]">
          <ButtonField
            value="Submit"
            classes="fw500 f13 radius-6 w30"
            name="primary"
            onClick={() => loading ? "" : handleSubmit()}
            loading={loading}
          />
        </div>
      </Drawer>
    </>
  );
};
export default DiscountDrawer;
