const DEVELOPMENT = {
  // BASE_URL: "https://quadkast-church-crm.el.r.appspot.com/api/",
  // BASE_URL: "http://54.213.136.43:8080/api",
  // BASE_URL: "http://localhost:8083/admin",
  BASE_URL:"https://controlpanel-dev-439516.uc.r.appspot.com/admin"
};

const PRODUCTION = {
  BASE_URL: "https://subscription-mgmt.uc.r.appspot.com/admin",
};

const STAGING = {
  // BASE_URL: "https://quadkast-church-crm.el.r.appspot.com/api",
  // BASE_URL: "http://54.213.136.43:8080/api",
  // BASE_URL: "http://localhost:8083/admin",
  // BASE_URL:"https://controlpanel-dev-439516.uc.r.appspot.com/admin",
  BASE_URL: "https://subscription-mgmt.uc.r.appspot.com/admin",

};

export default process.env.REACT_APP_ENV === "production"
  ? PRODUCTION
  : process.env.REACT_APP_ENV === "development"
  ? DEVELOPMENT
  : STAGING;
