import React, { useState } from "react";
import "./style.css";
import ModalPopUp from "../../Components/Modal/modalPopUp";
import InputField from "../../Components/InputField";
import ButtonField from "../../Components/ButtonField";
import { ShowError, isErrorTxt, passwordValidation } from "../../helpers/utils";
import { MESSAGES } from "../../constants/enum";
import { useAuth } from "../Auth/context";
const ChangePassword = ({ ...props }) => {
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    reEnterPassword: "",
    confirmPassword: "",
    loading: false,
  });
  const { currentPassword, newPassword, confirmPassword, loading } = password;

  const {
    actions: { ForgotPassword },
  } = useAuth();

  const handleChangePassword = () => {
    if (isErrorTxt(currentPassword)) {
      ShowError("Please enter the current password");
    } else if (isErrorTxt(newPassword)) {
      ShowError("Please enter the new password");
    } else if (passwordValidation(newPassword)) {
      ShowError(`${MESSAGES.password}`);
    } else if (isErrorTxt(confirmPassword)) {
      ShowError("Please enter the confirm password");
    } else if (newPassword !== confirmPassword) {
      ShowError("Password should be same");
    } else {
      setPassword((prev: any) => ({
        ...prev,
        loading: true,
      }));
      let data = {
        password: currentPassword,
        new_password: newPassword,
      };
      console.log(data)
      ForgotPassword(data, (res) => {
        setPassword((prev: any) => ({
          ...prev,
          loading: false,
        }));
        if (res.success) {
          props.setShowPopupModal(false);
          props.setModalType("");
        } else {
        }
      });
    }
  };
  const handleInputChange = (e: any, item: any) => {
    let value = e.target.value;
    let data: any = { ...password };
    data[item] = value;
    setPassword(data);
  };
  return (
    <ModalPopUp
      title="Change Password"
      setShowPopupModal={props.setShowPopupModal}
      setModalType={props.setModalType}
    >
      <div className="">
        <div className="text-style">
          <InputField
            type="password"
            label="Current password"
            placeholder="Enter current password"
            mandatory="true"
            lablestyle="labelStyle"
            value={currentPassword}
            onChange={(e: any) => handleInputChange(e, "currentPassword")}
          />
        </div>
        <div className="text-style">
          <InputField
            type="password"
            label="New password"
            placeholder="Enter new password"
            mandatory="true"
            lablestyle="labelStyle"
            value={newPassword}
            onChange={(e: any) => handleInputChange(e, "newPassword")}
          />
        </div>
        <div className="text-style">
          <InputField
            type="password"
            label="Confirm password"
            placeholder="Enter confirm password"
            mandatory="true"
            lablestyle="labelStyle"
            value={confirmPassword}
            onChange={(e: any) => handleInputChange(e, "confirmPassword")}
          />
        </div>
        <div className="flex  justify-end">
          <div className="w-[120px]">
            <ButtonField
              value="Save"
              name="primary"
              onClick={handleChangePassword}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </ModalPopUp>
  );
};

export default ChangePassword;
