import React from "react";
import "./style.css";
import ModalPopUp from "../../Components/Modal/modalPopUp";
import ButtonField from "../../Components/ButtonField";
import { RemoveAllCookie } from "../../helpers/utils";

import { useNavigate } from "react-router-dom";
const LogOut = ({ ...props }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    props.setShowPopupModal(false);
    props.setModalType("");
  };

  const handleLogout = () => {
    handleClose();
    RemoveAllCookie();
    window.location.reload();
    navigate(`/login`);
  };
  return (
    <ModalPopUp
      title="Logout"
      setShowPopupModal={props.setShowPopupModal}
      setModalType={props.setModalType}
      close={true}
    >
      <div className="">
        <div className="logout">
          <p>Are you sure you want to logout ? Please Confirm !</p>
          <div className="w-[200px] m-auto">
            <div className="flex gap-5 mt-[15px]">
              <ButtonField value="No" name="primary" onClick={handleClose} />
              <ButtonField
                value="Yes"
                name="secondary"
                onClick={handleLogout}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPopUp>
  );
};

export default LogOut;
