import React, { useEffect, useState, useRef } from "react";
import { SidebarMenu } from "./sidebarLoad";
import notification from "../../Assets/images/brnoti.svg";
import dumprofile from "../../Assets/images/brdummypro.svg";
import password from "../../Assets/icons/password.svg";
import lock from "../../Assets/icons/lock.svg";
import { NavLink, useLocation } from "react-router-dom";
import brlogo from "../../Assets/images/binlogo.svg";
import { RemoveCookie } from "../../helpers/utils";
import { AUTHENTICATION, SIDEBAR_PATH } from "../../constants/enum";
import ToolTip from "../../Components/Popover/ToolTip";
const Sidebar = ({ ...props }) => {
  const [sidebar, setSidebar] = useState(SidebarMenu);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  let path = location.pathname;
  let pathName = path.split("/");

  useEffect(() => {
    activeLink();
    if (path !== SIDEBAR_PATH.discount) {
      RemoveCookie(AUTHENTICATION.page_number);
    }
  }, [path]);

  const activeLink = () => {
    setSidebar(
      sidebar.map((el: any) => {
        if ("/" + pathName[1] === el.path) {
          el.isActive = true;
        } else {
          el.isActive = false;
        }
        return el;
      })
    );
  };

  const handleProfile = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleShowModal = (item: any) => {
    props.setShowPopupModal(true);
    props.setModalType(item);
    setShowProfileMenu(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="sidebar">
        <img src={brlogo} className="top-5 p-[13px] fixed bg-[#333] z-10" />

        <div className="mt-[100px] flex-1 overflow-auto relative">
          {sidebar &&
            sidebar.map((item, i) => (
              <div className="relative sidebar-nav flex">
                <div className={`mb-[30px]`} key={i}>
                  <ToolTip name={item.tooltip}>
                    <NavLink
                      to={item.path}
                      className={`${
                        item.isActive && "sidebar-active"
                      } sidebar-module`}
                    >
                      <img
                        src={item.isActive ? item.activeIcon : item.icon}
                        alt="pic"
                      />
                    </NavLink>
                  </ToolTip>
                </div>
                {/* <div className="tooltiptext">Tooltip text</div> */}
              </div>
            ))}
        </div>
        <div className="sidebar-footer">
          {/* <img src={notification} className="mb-[40px] ml-[8px]" /> */}
          <div className="profile-menu" ref={dropdownRef}>
            <ToolTip name={"Profile"}>
              <img src={dumprofile} className="" onClick={handleProfile} />
            </ToolTip>

            {showProfileMenu && (
              <div className="sidebar-popup">
                <p onClick={() => handleShowModal("change_password")}>
                  <img src={password} /> <span>Change Password</span>
                </p>
                <p onClick={() => handleShowModal("logout")}>
                  <img src={lock} />
                  <span>Log out</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
