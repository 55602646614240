import Cards from "../../Components/Cards";
import total from "../../Assets/icons/clickbr.svg";
import brcon from "../../Assets/icons/tsbr.svg";
import brcredit from "../../Assets/icons/scbr.svg";
import brunused from "../../Assets/icons/tcbr.svg";

const AffiliatedStats = ({ ...props }) => {
  const { affiliateCard } = props;
  const statsList = [
    {
      name: "Total Clicks",
      value: affiliateCard?.clicks ?? 0,
      icon: total,
    },
    {
      name: "Total Signup",
      value: affiliateCard?.signups ?? 0,
      icon: brcon,
    },
    {
      name: "Signup Conversion",
      value: affiliateCard?.subscriptions ?? 0,
      icon: brcredit,
    },
    {
      name: "Total Credits",
      value: affiliateCard?.credits ?? 0,
      icon: brunused,
      currency: true,
    },
    // {
    //   name: "Total unused credit",
    //   value: 200,
    //   icon: brunused,
    //   currency: true,
    // },
  ];
  return (
    <div className="flex items-center gap-5">
      {statsList && statsList.map((item, i) => <Cards item={item} key={i} />)}
    </div>
  );
};
export default AffiliatedStats;
