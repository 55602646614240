import { PageRouter } from "../../Components/Router/PageRouter";
import { SIDEBAR_PATH } from "../../constants/enum";
import AffliatedList from "./Affiliate";
import AffliatedDetail from "./AffiliatedDetail";
import AffliatedProfile from "./AffiliateProfile";
import { AffiliateProvider } from "./context";
import EmployerDetail from "./EmployerDetail";

export default function AffiliateLayout() {
  const data = [
    {
      path: SIDEBAR_PATH.affiliate,
      element: <AffliatedList />,
    },

    {
      path: SIDEBAR_PATH.affiliateDetail,
      element: <AffliatedDetail />,
    },
    {
      path: SIDEBAR_PATH.employerDetail,
      element: <EmployerDetail />,
    },
    {
      path: SIDEBAR_PATH.affiliateProfile,
      element: <AffliatedProfile />,
    },
    {
      path: SIDEBAR_PATH.employerProfile,
      element: <AffliatedProfile />,
    },
  ];
  return (
    <AffiliateProvider>
      <PageRouter data={data} />
    </AffiliateProvider>
  );
}
