import dashboard from "../../Assets/images/dash.svg";
import organi from "../../Assets/images/organization.svg";
import subscrip from "../../Assets/images/subscr.svg";
import affiliate from "../../Assets/images/affli.svg";
import exit from "../../Assets/images/exitbump.svg";
import discount from "../../Assets/images/discount.svg";
import actdashboard from "../../Assets/images/brdash.svg";
import actorgani from "../../Assets/images/actOrg.svg";
import actsubscrip from "../../Assets/images/actSub.svg";
import actaffiliate from "../../Assets/images/actAffiliate.svg";
import actexit from "../../Assets/images/actExit.svg";
import actdiscount from "../../Assets/images/actDiscount.svg";
import AffiliateLayout from "../Affiliate/layout";
// import activeDashboard from "../../Assets/images/brdash.svg";

import {
  DASHBOARD_KEY,
  SIDEBAR_KEYS,
  SIDEBAR_PATH,
} from "../../constants/enum";
import ExitLayout from "../ExitBumps/layout";
import OrganizationLayout from "../Organization/layout";
import DashboardLayout from "../Dashboard/layout";
import DiscountLayout from "../Discount/layout";

export const SidebarMenu = [
  {
    title: SIDEBAR_KEYS.dashboard,
    path: SIDEBAR_PATH.dashboard,
    icon: dashboard,
    activeIcon: actdashboard,
    isActive: false,
    data: <DashboardLayout />,
    tooltip:SIDEBAR_KEYS.dashboard
  },
  {
    title: SIDEBAR_KEYS.organizations,
    path: SIDEBAR_PATH.organizations,
    icon: organi,
    activeIcon: actorgani,
    isActive: false,
    data: <OrganizationLayout />,
    tooltip:SIDEBAR_KEYS.organizations

  },
  // {
  //   title: SIDEBAR_KEYS.subscription,
  //   path: SIDEBAR_PATH.subscription,
  //   icon: subscrip,
  //   activeIcon: actsubscrip,
  //   isActive: false,
  // tooltip:SIDEBAR_KEYS.subscription
  // },

  {
    title: SIDEBAR_KEYS.affiliate,
    path: SIDEBAR_PATH.affiliate,
    icon: affiliate,
    activeIcon: actaffiliate,
    isActive: false,
    data: <AffiliateLayout />,
    tooltip:SIDEBAR_KEYS.affiliate_marketing
  },
  {
    title: SIDEBAR_KEYS.exit,
    path: SIDEBAR_PATH.exit,
    icon: exit,
    activeIcon: actexit,
    isActive: false,
    data: <ExitLayout />,
    tooltip: SIDEBAR_KEYS.exit_bumps,

  },
  {
    title: SIDEBAR_KEYS.discount,
    path: SIDEBAR_PATH.discount,
    icon: discount,
    activeIcon: actdiscount,
    isActive: false,
    data: <DiscountLayout />,
    tooltip: SIDEBAR_KEYS.discount_codes,

  },
];
