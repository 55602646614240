import React, { useState } from "react";
import LayoutModule from "../../Layouts/layout";
import Sidebar from "../sidebar/sidebar";
import ChangePassword from "../sidebar/ChangePassword";
import LogOut from "../sidebar/LogOut";
import { AuthProvider } from "../Auth/context";
import Content from "../../Layouts/content";

const PrivateRouter = () => {
  const [showPopupModal, setShowPopupModal] = useState(true);
  const [ModalType, setModalType] = useState("");

  return (
    <AuthProvider>
      {" "}
      <div className="flex w-[100%] relative">
        {ModalType === "change_password" && showPopupModal && (
          <ChangePassword
            setShowPopupModal={setShowPopupModal}
            setModalType={setModalType}
          />
        )}
        {ModalType === "logout" && showPopupModal && (
          <LogOut
            setShowPopupModal={setShowPopupModal}
            setModalType={setModalType}
          />
        )}

        <div className="">
          <Sidebar
            setShowPopupModal={setShowPopupModal}
            setModalType={setModalType}
          />
        </div>
        <div className="content w-[100vw]">
          <Content />
          {/* <LayoutModule /> */}
        </div>
      </div>
    </AuthProvider>
  );
};

export default PrivateRouter;
