import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default function LineChart({ ...props }) {
  const { dashboardChart } = props;
  let setColor1 =
    props.type === "red"
      ? "rgba(241, 76, 82, 0.36)"
      : "rgba(79, 209, 197, 0.54)";
  let setColor2 =
    props.type === "red" ? "rgba(241, 76, 82, 0)" : "rgba(79, 209, 197, 0)";
  let borderColor =
    props.type === "red" ? "rgba(241, 76, 82, 1)" : "rgba(79, 209, 197, 1)";
  let pointhoverbg = props.type === "red" ? "#F14C52" : "#4FD1C5";

  const monthToNumber = (month: string): number => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames.indexOf(month) + 1; // Add 1 because indexOf starts from 0
  };

  const sortedSignupData = dashboardChart?.sort((a: any, b: any) => {
    const dateA = new Date(a.year, monthToNumber(a.month) - 1); // Convert month to number
    const dateB = new Date(b.year, monthToNumber(b.month) - 1);
    return dateA.getTime() - dateB.getTime();
  });

  // Prepare the labels (months) and data (counts) for the chart
  const chartLabels = sortedSignupData?.map(
    (data: any) => data.day?`${data.day} ${data.month}`:`${data.month} ${data.year}`
  );
  const chartData = sortedSignupData?.map((data: any) => data.count);

  const revenue: any = () => {
    return {
      labels: chartLabels,
      datasets: [
        {
          label: "",
          backgroundColor: (context: any) => {
            const ctx: any = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, setColor1);
            gradient.addColorStop(0.5, setColor2);
            gradient.addColorStop(1, "rgba(0, 0, 0, 0.04)");
            return gradient;
          },
          borderWidth: 1.5,
          borderRadius: 7,
          barThickness: 26,
          pointRadius: 0,
          hitRadius: 25,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: pointhoverbg,
          pointHoverBorderColor: "#fff",
          pointHoverBorderWidth: 3,
          fill: "origin",
          borderColor: borderColor,
          tension: 0.4,
          data: chartData,
          // [100, 50, 90, 120, 130, 80, 200, 150, 115, 90, 40, 20],
        },
      ],
    };
  };

  const optionData: any = {
    responsive: true,
    maintainAspectRatio: true,
    animation: {
      easing: "easeInOutQuad",
      duration: 520,
    },

    tooltipFillColor: "rgba(0,0,0,0.8)",

    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },

      title: {
        position: "bottom",
        display: true,
        font: {
          size: 15,
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          color: "#F3F4F6",
        },
        border: {
          display: false,
        },

        ticks: {
          stepSize: 0,
          precision: 0,
          font: {
            size: "10px",
            weight: 500,
            lineHeight: 1.2,
          },
          //   callback: (value: any) => value,
          color: "#A0AEC0",
        },
      },
      y: {
        beginAtZero: true,

        grid: {
          color: "#E4EDF0",
          display: true,
          tickWidth: 0,
          stepType: 100,
          tickMarkLength: 15,
          fontSize: 40,
        },
        border: {
          display: false,
          dash: [5, 5],
        },

        ticks: {
          stepSize: 0,
          precision: 0,
          font: {
            size: "10px",
            weight: 400,
            lineHeight: 2.6,
          },
          callback: (value: any) => value,
          //   padding: 10,
          color: "#A0AEC0",
        },
      },
    },
  };
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Line data={revenue()} options={optionData} />
    </div>
  );
}
