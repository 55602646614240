import { useReducer } from "react";
import { container } from "../../userContext/userContext";
import { APIService } from "../../constants/request";
import { buildQueryString, ShowError, ShowSuccess } from "../../helpers/utils";
// import { useToast } from "../../components/toast";

export type StateType = {
  affiliate_detail: any;
};

const initialState: StateType = {
  affiliate_detail: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "AFFILIATE_PROFILE":
      return { ...state, affiliate_detail: payload };
    default:
      return state;
  }
};
export const { useContext: useAffiliate, Provider: AffiliateProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });

    const GetAffiliateList = (
      type: any,
      params: any,
      callback?: (data: any) => void
    ) => {
      let param = params ? buildQueryString(params, 1, "?") : "";
      APIService.get(`/affiliate/affiliators?type=${type}${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };
    const GetAffiliateChart = (type: any, callback?: (data: any) => void) => {
      APIService.get(`/affiliate/affiliators/statis?type=${type}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };
    const GetAffiliateCard = (type: any, callback?: (data: any) => void) => {
      APIService.get(`/affiliate/affiliators/dashboard?type=${type}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetEmployerList = (
      type: any,
      params: any,
      callback?: (data: any) => void
    ) => {
      let param = params ? buildQueryString(params, 1, "?") : "";
      APIService.get(`/affiliate/employers?type=${type}${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };
    const GetEmployerChart = (param: any, callback?: (data: any) => void) => {
      APIService.get(`/affiliate/employers/statis?type=${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };
    const GetEmployerCard = (type: any, callback?: (data: any) => void) => {
      APIService.get(`/affiliate/employers/dashboard?type=${type}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetAffiliateJoinCard = (id: any, callback?: (data: any) => void) => {
      APIService.get(`/affiliate/affiliators/dashboard/${id}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetAffiliateJoinDetail = (
      id: any,
      callback?: (data: any) => void
    ) => {
      APIService.get(`/affiliators/${id}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetAffiliateJoinList = (
      id: any,
      params: any,
      callback?: (data: any) => void
    ) => {
      let param = params ? buildQueryString(params, 1) : "";
      APIService.get(`/affiliate/affiliators/${id}/referrals${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetAffiliateTransactionList = (
      id: any,
      params: any,
      callback?: (data: any) => void
    ) => {
      let param = params ? buildQueryString(params, 1) : "";
      APIService.get(`/affiliate/employers/${id}/referrals${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetEmployerJoinCard = (id: any, callback?: (data: any) => void) => {
      APIService.get(`/affiliate/employers/dashboard/${id}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetEmployerJoinDetail = (id: any, callback?: (data: any) => void) => {
      APIService.get(`/employers/${id}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetEmployerJoinList = (
      id: any,
      params: any,
      callback?: (data: any) => void
    ) => {
      let param = params ? buildQueryString(params, 1) : "";
      APIService.get(`/affiliate/employers/${id}/referrals${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const GetEmployerCreditList = (
      id: any,
      params: any,
      callback?: (data: any) => void
    ) => {
      let param = params ? buildQueryString(params, 1) : "";
      APIService.get(`/affiliate/employers/${id}/credits${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    return {
      state,
      actions: {
        GetAffiliateCard,
        GetAffiliateChart,
        GetAffiliateList,
        GetEmployerChart,
        GetEmployerCard,
        GetEmployerList,
        GetAffiliateJoinList,
        GetAffiliateJoinCard,
        GetAffiliateTransactionList,
        GetAffiliateJoinDetail,
        GetEmployerJoinCard,
        GetEmployerJoinDetail,
        GetEmployerJoinList,
        GetEmployerCreditList
      },
    };
  });
