import { Navigate } from "react-router-dom";
import { PageRouter } from "../../Components/Router/PageRouter";
import { SIDEBAR_PATH } from "../../constants/enum";
import OrganizationList from "./organisationList";
import { OrganizationProvider } from "./context";

export default function OrganizationLayout() {
  const data = [
    {
      path: SIDEBAR_PATH.organizations,
      element: <OrganizationList />,
    },
  ];

  return (
    <OrganizationProvider>
      <PageRouter data={data} />
    </OrganizationProvider>
  );
}
