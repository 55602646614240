import { useEffect, useState } from "react";
import Breadcrumb from "../../Components/breadCrump";
import InputField from "../../Components/InputField";
import { useParams } from "react-router-dom";
import { useAffiliate } from "./context";
import { Spinner } from "../../helpers/utils";

const AffliatedProfile = () => {
  const [affiliateDetail, setAffiliateDetail]: any = useState("");
  const [employerDetail, setEmployerDetail]: any = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const employer = window.location.pathname.includes("employer");
  const {
    actions: { GetAffiliateJoinDetail, GetEmployerJoinDetail },
  } = useAffiliate();

  useEffect(() => {
    if (employer) {
      setLoading(true);
      GetEmployerJoinDetail(id, (res: any) => {
        setEmployerDetail(res.data?.employer);
        setLoading(false);
      });
    } else {
      setLoading(true);
      GetAffiliateJoinDetail(id, (res: any) => {
        setAffiliateDetail(res.data?.affiliator);
        setLoading(false);
      });
    }
  }, []);

  const breadcrumbLinks = [
    { name: "Affiliate", path: "/affiliate" },
    {
      name: employer ? employerDetail?.name : affiliateDetail?.name,
      path: employer ? `/employer/${id}` : `/affiliate/${id}`,
    },
    { name: "Profile", path: "" },
  ];

  return (
    <div className="">
      <Breadcrumb links={breadcrumbLinks} />
      <div className="mt-[30px] mb-[20px]">
        <span className="f18 fw500 cl333333">Basic Details</span>
      </div>
      {loading ? (
        <div className="spincenter h-[100%] flex items-center">
          <Spinner size={80} color={"spinred"} />
        </div>
      ) : (
        <>
          <div className="white-space">
            <div className="flex gap-5 w-[100%]">
              <div className="flexCol w-[50%]">
                <span className="cl757575 f14 mb6">
                  {employer ? "Organization" : "Name"}
                </span>
                <div className="text-style">
                  {/* <div className="">
                {employer
                  ? employerDetail?.name
                  : affiliateDetail?.organization}
              </div> */}
                  <InputField
                    readOnly={true}
                    value={
                      employer ? employerDetail?.name : affiliateDetail?.name
                    }
                  />
                </div>
              </div>
              <div className="flexCol w-[50%]">
                <span className="cl757575 f14 mb6">
                  {employer ? "Contact name" : "Organization"}
                </span>
                <div className="text-style">
                  <InputField
                    readOnly={true}
                    value={
                      employer
                        ? employerDetail?.contact_name
                        : affiliateDetail?.organization
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-5 w-[100%]">
              <div className="flexCol w-[50%]">
                <span className="cl757575 f14 mb6">Email id</span>
                <div className="text-style">
                  <InputField
                    readOnly={true}
                    value={
                      employer ? employerDetail?.email : affiliateDetail?.email
                    }
                  />
                </div>
              </div>
              <div className="flexCol w-[50%]">
                <span className="cl757575 f14 mb6">Phone number</span>
                <div className="text-style">
                  <InputField
                    readOnly={true}
                    value={
                      employer ? employerDetail?.phone : affiliateDetail?.phone
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex w-[100%]">
              <div className="flexCol w-[100%]">
                <span className="cl757575 f14 mb6">Address</span>
                <div className="mb-[12px] w-[100%]">
                  <textarea
                    className="w-[100%] inputBorder min-h-[90px] p-[10px] focus:outline-none resize-none"
                    placeholder="Address"
                    readOnly={true}
                    value={
                      employer && employerDetail?.address
                        ? employerDetail?.address?.line1 ??
                          "" + employerDetail?.address?.city ??
                          "" + ", " + employerDetail?.address?.country ??
                          "" + ", " + employerDetail?.address?.postal_code
                        : affiliateDetail?.address ?? ""
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div className="flex w-[100%]">
          <div className="flexCol w-[100%]">
            <span className="cl757575 f14 mb6">Organization</span>
            <div className="text-style">
              <InputField
                classes="w-[100%]"
                placeholder="Phone number"
                lablestyle="labelStyle"
                value={
                  employer
                    ? employerDetail?.name
                    : affiliateDetail?.organization
                }
              />
            </div>
          </div>
        </div> */}
          </div>
          {!employer && (
            <div>
              <div className="mt-[30px] mb-[20px]">
                <span className="f18 fw500 cl333333">Bank Details</span>
              </div>
              <div className="white-space">
                <div className="flex gap-5 w-[100%]">
                  <div className="flexCol w-[50%]">
                    <span className="cl757575 f14 mb6">Account type</span>
                    <div className="text-style">
                      <InputField
                        readOnly={true}
                        value={affiliateDetail?.bank?.type}
                      />
                    </div>
                  </div>
                  <div className="flexCol w-[50%]">
                    <span className="cl757575 f14 mb6">
                      Name of the financial institution
                    </span>
                    <div className="text-style">
                      <InputField
                        readOnly={true}
                        value={affiliateDetail?.bank?.name}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 w-[100%]">
                  <div className="flexCol w-[50%]">
                    <span className="cl757575 f14 mb6">Routing number</span>
                    <div className="text-style">
                      <InputField
                        readOnly={true}
                        value={affiliateDetail?.bank?.route_number}
                      />
                    </div>
                  </div>
                  <div className="flexCol w-[50%]">
                    <span className="cl757575 f14 mb6">Account number</span>
                    <div className="text-style">
                      <InputField
                        readOnly={true}
                        value={affiliateDetail?.bank?.acc_number}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AffliatedProfile;
