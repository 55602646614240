import { useEffect, useState } from "react";
import { SidebarMenu } from "../Pages/sidebar/sidebarLoad";

export default function Content() {
  const [data, setData]: any = useState([]);
  const [pathArray, setPathArray]: any = useState([]);

  let activeModule: any[] = [];

  // const pathLength = location.pathname.split("/");
  // let modulePath: any = "/" + pathLength[1];
  // let path = location.pathname;

  useEffect(() => {
    let moduleArray: any = [];
    SidebarMenu?.map((item: any) => {
      if (item.subNav) {
        item.subNav.forEach((sub: any) => {
          moduleArray.push(sub?.path ?? "");
          if (sub.title) {
            activeModule.push(sub.data);
          }
        });
      } else if (item.title) {
        moduleArray.push(item?.path ?? "");
        activeModule.push(item.data);
      }
    });
    setPathArray(moduleArray);
    setData(activeModule);
  }, [])

  return <div>{data}</div>;
}
