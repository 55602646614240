import { useEffect, useState } from "react";
import openEye from "../Assets/icons/brOpeneye.svg";
import closeEye from "../Assets/icons/brCloseEye.svg";
const InputField = ({ ...props }) => {
  const [type, setType] = useState(props.type ?? "text");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisiblity = () => {
    const show = !showPassword;
    setShowPassword(!showPassword);
    setType(show ? "text" : "password");
  };

  useEffect(() => {}, [type]);

  return (
    <>
      {props.label && (
        <label className={props.lablestyle ? props.lablestyle : "input-label"}>
          {props.label}
          {props.mandatory && <span className="text-primary">*</span>}
        </label>
      )}
      <div className="relative">
        <input
          type={type}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className={props.classes}
          onKeyDown={props.onkeydown}
          min={props.min}
          max={props.max}
          step={props.step}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
        {props.type === "password" && (
          <span className="ml-3 absolute right-3 top-3.5">
            {showPassword ? (
              <img
                src={openEye}
                className="pass-icon"
                onClick={togglePasswordVisiblity}
                alt="open"
              />
            ) : (
              <img
                src={closeEye}
                className="pass-icon"
                onClick={togglePasswordVisiblity}
                alt="close"
              />
            )}
          </span>
        )}
      </div>
    </>
  );
};
export default InputField;
