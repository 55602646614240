import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spinner } from "../helpers/utils";

const ButtonField = ({ ...props }) => {
  const { loading, value, spinSize, SpinColor } = props;
  return (
    <button
      className={`${
        props.name === "primary" ? "btn-primary" : "btn-secondary"
      } btn ${props.classes}`}
      {...props}
    >
      {props.loading ? (
        <Spinner size={spinSize} color={props.spincolor??SpinColor} tip={"yes"} />
      ) : (
        value
      )}
    </button>
  );
};
export default ButtonField;
