import { useEffect, useState } from "react";
import {
  Nodata,
  ShowDate,
  Spinner,
  formattedCurrency,
  searchQuery,
  showContent,
  showSubLevel,
} from "../../helpers/utils";
import "./style.css";
import AffiliatedStats from "./AffiliatedStats";
import { data } from "./loadData";
import TableHeader from "../../Components/tableHeader";
import EmptyProfile from "../../Assets/icons/emptyProfile.svg";
import ButtonField from "../../Components/ButtonField";
import Breadcrumb from "../../Components/breadCrump";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAffiliate } from "./context";
import TablePagination from "../../Components/Pagination/Pagination";

const AffliatedDetail = () => {
  const [selectTab, setSelectTab] = useState("Referred Users");
  // const tablist = ["Referred Users", "Credit history"];
  const tablist = ["Referred Users"];

  const [affiliateJoinList, setAffiliateJoinList]: any = useState([]);
  const [affiliateJoinCard, setAffiliateJoinCard]: any = useState("");
  const [employerTransList, setEmployerTransList]: any = useState([]);
  const [employerTransCard, setEmployerTransCard]: any = useState("");
  const [affiliateDetail, setAffiliateDetail]: any = useState("");
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal]: any = useState(null);

  const [initialState, setInitialState] = useState({
    page: 1,
    limit: 10,
    name: "",
  });
  const { page, limit, name } = initialState;
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    actions: {
      GetAffiliateJoinList,
      GetAffiliateTransactionList,
      GetAffiliateJoinCard,
      GetAffiliateJoinDetail,
    },
  } = useAffiliate();

  const handleTab = (tab: any) => {
    setSelectTab(tab);
  };

  const query = {
    limit,
    page,
    name,
  };

  let searchdata = searchQuery(query);

  useEffect(() => {
    if (selectTab === "Referred Users") {
      GetAffiliateJoinCard(id, (res: any) => {
        setAffiliateJoinCard(res.data?.referral_statis);
      });
      GetAffiliateJoinDetail(id, (res: any) => {
        setAffiliateDetail(res.data?.affiliator);
      });
    }
  }, []);

  useEffect(() => {
    ListData();
  }, [name, page, selectTab]);

  const ListData = () => {
    handleTable(searchdata);
  };

  const handleTable = (data: any) => {
    setLoading(true);
    if (selectTab === "Referred Users") {
      GetAffiliateJoinList(id, data, (res: any) => {
        setLoading(false);
        setAffiliateJoinList(res.data?.referral_employers);
        setTotal(res?.data?.total);
      });
    } else if (selectTab === "Credit history") {
      GetAffiliateTransactionList(id, data, (res: any) => {
        setLoading(false);
        setEmployerTransList(res.data?.referral_employers);
        setTotal(res?.data?.total);
      });
    }
    setSearchParams(searchdata);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialState({
        ...initialState,
        name: searchData,
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchData]);

  const handlePageChange = (e: any) => {
    setInitialState({
      ...initialState,
      page: e,
    });
  };
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Affiliate", path: "/affiliate" },
    { name: "Details" },
  ];

  return (
    <div className="">
      <Breadcrumb links={breadcrumbLinks} />
      <div className="mt-[30px] mb-[24px]">
        <AffiliatedStats affiliateCard={affiliateJoinCard} />
      </div>
      <div className="white-space">
        <div className="flex align-center justify-between mb15">
          {" "}
          <div className="flex align-center">
            <img src={EmptyProfile} className="mr10" />
            <span>{affiliateDetail.name}</span>
          </div>
          <div>
            <ButtonField
              value="View profile"
              classes="fw400 f13"
              name="primary"
              onClick={() => navigate(`/affiliate/profile/${id}`)}
            />
          </div>
        </div>
        <div className="flex">
          {/* <div className="flexCol w14">
            <span className="clB5B5B5 f14 mb6">User name</span>
            <span className="f14">{affiliateDetail.name}</span>
          </div> */}
          <div className="flexCol w14 mr-4">
            <span className="clB5B5B5 f14 mb6">Email id</span>
            <span className="f14">{affiliateDetail.email}</span>
          </div>
          <div className="flexCol w14">
            <span className="clB5B5B5 f14 mb6">Phone number</span>
            <span className="f14">{affiliateDetail.phone}</span>
          </div>

          <div className="flexCol w14">
            <span className="clB5B5B5 f14 mb6">Organization</span>
            <span className="f14">{affiliateDetail.organization}</span>
          </div>
        </div>
      </div>
      <div className="tabname mt20">
        {tablist &&
          tablist.map((tab, t) => (
            <div
              key={t}
              className={`${selectTab === tab ? "select-tab" : "unselect-tab"}`}
              onClick={() => handleTab(tab)}
            >
              <p>{tab}</p>
            </div>
          ))}
      </div>
      <div className="mt-[24px] bg-[#fff] rounded-[6px]">
        <TableHeader
          onChange={(e: any) => setSearchData(e.target.value)}
          searchLabel={
            selectTab === "Referred Users"
              ? "Search Employers"
              : "Search History"
          }
        />
        <div>
          <table>
            <thead>
              <tr>
                {/* <th className="max-w-100 fw600">USER NAME</th>
                <th className="fw600">SIGNUP DATE</th>
                <th className="fw600">STATUS</th>
                <th className="fw600">CREDIT STATUS</th>
                <th className="fw600">CREDITS</th> */}
                <th className="max-w-100 fw600">NAME</th>
                <th className="fw600">SIGNUP DATE</th>
                {/* <th className="fw600">STATUS</th> */}
                <th className="fw600">PLAN</th>
                <th className="fw600">SUBSCRIPTION DATE</th>
                {/* <th className="fw600">CREDITS</th> */}
              </tr>
            </thead>
            {selectTab === "Referred Users" && (
              <>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Spinner size={80} color={"spinred"} />
                      </td>
                    </tr>
                  </tbody>
                ) : !affiliateJoinList?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter h50vh">
                        <Nodata height={80} />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {affiliateJoinList.map((item: any, i: any) => (
                      <tr key={i}>
                        <td className="max-w-100 pointer">
                          <div className="row-centers">
                            <img
                              src={item?.logo_url ?? EmptyProfile}
                              className="mr-[5px] w-[33px] rounded-[50%]"
                            />
                            <span className="text-[#333333] truncate-line f13 fw500 capitalize">
                              {showContent(item.employer?.name)}
                            </span>
                          </div>
                        </td>
                        <td>{showContent(ShowDate(item.createdAt))}</td>
                        {/* <td>
                          {showContent(showSubLevel(item.employer?.status))}
                        </td> */}
                        <td>{showContent(item.subscription?.app)}</td>
                        <td>
                          {showContent(ShowDate(item.subscription?.sub_date))}
                        </td>
                        {/* <td>
                          {showContent(
                            formattedCurrency(item.subscription?.credits)
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            )}
            {selectTab === "Credit history" && (
              <>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Spinner size={80} color={"spinred"} />
                      </td>
                    </tr>
                  </tbody>
                ) : !employerTransList?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter h50vh">
                        <Nodata />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {employerTransList.map((item: any, i: any) => (
                      <tr key={i}>
                        <td
                          className="max-w-100 pointer"
                          onClick={() => navigate(`/affiliate/${item._id}`)}
                        >
                          <div className="row-centers">
                            <img src={item.icon} alt={item.name} />
                            <span className="text-[#333333] truncate-line">
                              {item?.employer?.name}
                            </span>
                          </div>
                        </td>
                        <td>
                          {showContent(ShowDate(item?.employer?.createdAt))}
                        </td>
                        <td>
                          {showContent(ShowDate(item?.employer?.updatedAt))}
                        </td>
                        <td>{showContent(formattedCurrency(item.cost))}</td>
                        <td>{showContent(formattedCurrency(item.cost))}</td>
                        <td>
                          {showContent(showSubLevel(item?.employer?.status))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            )}
          </table>
        </div>
      </div>
      <TablePagination
        current={initialState.page ?? 1}
        length={
          selectTab === "Referred Users"
            ? affiliateJoinList.length
            : employerTransList.length
        }
        pageSize={10}
        total={total}
        onChange={(e: any) => handlePageChange(e)}
      />
    </div>
  );
};

export default AffliatedDetail;
