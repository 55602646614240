import { DatePicker } from "antd";

const DateField = ({ ...props }) => {
  const { onChange, value,disabledDate } = props;
  return (
    <div>
      <DatePicker
        onChange={onChange}
        className="w-[100%] h-[46px]"
        placeholder="mm/dd/yyyy"
        format="MM/DD/YYYY"
        value={value}
        disabledDate={disabledDate}
      />
    </div>
  );
};

export default DateField;
