import { useState, useRef, useEffect } from "react";
import dropd from "../../Assets/icons/dropdown.svg";
import { JoinMethod } from "../../helpers/utils";

const Multipleselect = ({ ...props }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  let selectItem = props.value && props.value;
  // const [selectItem, setSelectItem]: any = useState(props.value && props.value);
  const [selectItem2, setSelectItem2]: any = useState([]);
  useEffect(() => {
    // console.log(selectItem[selectItem.length - 1])
    const handleClickOutside = (event: any) => {
      // If the clicked target is not inside the dropdown, close it
      if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const CheckLabel = (arr: any, label: any) => {
    return arr.find((obj: any) => obj.label === label);
  };

  return (
    <div ref={dropdownRef} className="relative">
      <div
        className="dropdown row-center overflow-hidden"
        onClick={() => setShow(!show)}
      >
        <div className="flex inline-block w-[250px] overflow-hidden">
          {/* {selectItem && selectItem.length > 0
            ? selectItem?.map((val: any, i: any) => {
                return (
                  <div key={i} className="flex">
                    <span>{val.label}</span>
                    <span>
                      {selectItem.length - 1 === i ? "..." : ","}
                    </span>
                  </div>
                );
              })
            : props.placeholder ?? "Select"} */}
          {selectItem && selectItem.length > 0
            ? <span>{JoinMethod(selectItem)}</span>
            : props.placeholder ?? "Select"}
        </div>
        <img src={dropd} className="ml-[15px]" />
      </div>
      {show && props.data && (
        <div className="multi-dropdown-list z-[2]">
          {props.data.map((item: any, i: any) => {
            return (
              <div
                className="flex items-center gap-2"
                onClick={() => props.handleClicks(item, i)}
                key={i}
              >
                <div className="mb-[-3px]">
                  <input
                    type="checkbox"
                    checked={CheckLabel(selectItem, item.label) ? true : false}
                  />
                </div>
                <p
                  className={`${
                    CheckLabel(selectItem, item.label) && "text-[#f14c52]"
                  }`}
                >
                  {item.label}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default Multipleselect;
