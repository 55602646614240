import { Popover } from "antd";
import { capitalize } from "../../helpers/utils";

const PopOver = ({ ...props }) => {
  return (
    <Popover className={`text-[#464243] cursor-pointer`} content={capitalize(props.content)}>
      {props.children}
    </Popover>
  );
};

export default PopOver;
