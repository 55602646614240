import { useReducer } from "react";
import { container } from "../../userContext/userContext";
import { APIService } from "../../constants/request";
import { buildQueryString, ShowError, ShowSuccess } from "../../helpers/utils";
// import { useToast } from "../../components/toast";

export type StateType = {
  supports: any;
};

const initialState: StateType = {
  supports: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    // case "GET_LOGIN":
    //   return { ...state, login: payload };
    default:
      return state;
  }
};
export const { useContext: useDiscount, Provider: DiscountProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });

    const createCoupon = (data: any, callback?: (data: any) => void) => {
      postMethod("/coupon", data, "", callback);
    };

    const postMethod = (
      url: any,
      data: any,
      route: any,
      callback?: (data: any) => void
    ) => {
      APIService.post(url, data, route)
        .then((res) => {
          if (res) {
            ShowSuccess(res.message);
            callback && callback(res);
          }
        })
        .catch((error) => {
          ShowError(error.message);
          callback && callback(error);
        });
    };

    const GetCouponList = (params: any, callback?: (data: any) => void) => {
      let param = params ? buildQueryString(params, 1) : "";

      APIService.get(`/coupon${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };

    const UpdateActiveDeactivate = (
      data: any,
      callback?: (data: any) => void
    ) => {
      APIService.patch(`/coupon`, data)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {
          ShowError(error.message);
          callback && callback(error);
        });
    };

    return {
      state,
      actions: {
        GetCouponList,
        UpdateActiveDeactivate,
        createCoupon,
      },
    };
  });
