import Cards from "../../Components/Cards";
import cusomer from "../../Assets/icons/customer.svg";
import free from "../../Assets/icons/brfree.svg";
import trail from "../../Assets/icons/trailbr.svg";
import paid from "../../Assets/icons/paid.svg";
import { useNavigate } from "react-router-dom";

const DashboardStats = ({ ...props }) => {
  const { data } = props;
  const statsList = [
    {
      name: "All Customers",
      value: data.all_customers ?? "0",
      icon: cusomer,
      path: "/organizations",
    },
    {
      name: "Free",
      value: data.free ?? "0",
      icon: free,
      path: "/organizations?page=1&limit=10&subType=free",
    },
    {
      name: "Paid",
      value: data.paid ?? "0",
      icon: paid,
      path: "/organizations?page=1&limit=10&subType=paid",
    },
    {
      name: "Trail",
      // value: data.trial ?? "0",
      value: "0",

      icon: trail,
    },
  ];
  return (
    <div className="flex items-center gap-5">
      {statsList && statsList.map((item, i) => <Cards item={item} key={i} />)}
    </div>
  );
};
export default DashboardStats;
