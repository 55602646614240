import { useState, useRef, useEffect } from "react";
import dropd from "../../Assets/icons/dropdown.svg";
import schedule_call from "../../Assets/exit_bumps/schedule_call.svg";

const StatusDropDown = ({ ...props }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // If the clicked target is not inside the dropdown, close it
      if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={dropdownRef} className="relative">
      <div className="statusdropdown row-center" onClick={() => setShow(!show)}>
     
        <span>
          {props.value?.label
            ? props.value?.label
            : props.placeholder ?? "Schedule"}
        </span>{" "}
        <img src={dropd} className="ml-[15px] status-icon" />
      </div>
      {show && props.data && (
        <div className="statusdropdown-list">
          {props.data.map((item: any, i: any) => {
            return (
              <p
                key={i}
                className=""
                onClick={() => {
                  props.HandleDropdown(item);
                  setShow(false);
                }}
              >
                {item.label}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default StatusDropDown;
