import { useReducer } from "react";
import { container } from "../../userContext/userContext";
import { APIService } from "../../constants/request";
import { ShowError, ShowSuccess } from "../../helpers/utils";
// import { useToast } from "../../components/toast";

export type StateType = {
  supports: any;
};

const initialState: StateType = {
  supports: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    // case "GET_LOGIN":
    //   return { ...state, login: payload };
    default:
      return state;
  }
};
export const { useContext: useAuth, Provider: AuthProvider } = container(() => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  //   const { showSuccessToast, showErrorToast } = useToast();

  const Login = (data: any, callback?: (data: any) => void) => {
    postMethod("/auth/signin", data, "", callback);
  };

  // const ForgotPassword = (data: any, callback?: (data: any) => void) => {
  //   postMethod("/profile/change-password", data, "", callback);
  // };

  const ForgotPassword = (data: any, callback?: (data: any) => void) => {
    APIService.patch(`/profile/change-password`, data)
      .then((res) => {
        ShowSuccess(res.message);
        callback && callback(res);
      })
      .catch((error) => {
        ShowError(error.message);
        callback && callback(error);
      });
  };

  const postMethod = (
    url: any,
    data: any,
    route: any,
    callback?: (data: any) => void
  ) => {
    APIService.post(url, data, route)
      .then((res) => {
        if (res) {
          ShowSuccess(res.message);
          callback && callback(res);
        }
      })
      .catch((error) => {
        ShowError(error.message);
        callback && callback(error);

      });
  };

  return {
    state,
    actions: {
      Login,
      ForgotPassword,
      // ResetPassword,
    },
  };
});
