import React, { useEffect, useState } from "react";
import {
  Nodata,
  ShowDate,
  Spinner,
  ellipsis,
  formattedCurrency,
  searchQuery,
  showContent,
  showSubLevel,
} from "../../helpers/utils";
import DropDownField from "../../Components/dropdown/dropdownField";
import "./style.css";
import AffiliatedStats from "./AffiliatedStats";
import AffiliatedChart from "./Affiliatedchart";
import { data } from "./loadData";
import TableHeader from "../../Components/tableHeader";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useAffiliate } from "./context";
import TablePagination from "../../Components/Pagination/Pagination";
import EmptyProfile from "../../Assets/icons/emptyProfile.svg";

const dropDownList = [
  {
    label: "This month",
    value: "monthly",
  },
  {
    label: "This year",
    value: "yearly",
  },
];

const AffliatedList = () => {
  const [selectTab, setSelectTab] = useState("Affiliators");
  const tablist = ["Affiliators", "Organizations"];
  const [affiliateList, setAffiliateList]: any = useState([]);
  const [affiliateCard, setAffiliateCard]: any = useState("");
  const [affiliateChart, setAffiliateChart]: any = useState("");
  const [employerList, setEmployerList]: any = useState([]);
  const [employerCard, setEmployerCard]: any = useState("");
  const [employerChart, setEmployerChart]: any = useState("");
  const [selectDropdown, setSelectDropdown]: any = useState(dropDownList[0]);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal]: any = useState(null);
  const [initialState, setInitialState] = useState({
    page: 1,
    limit: 10,
    name: "",
  });
  const { page, limit, name } = initialState;
  const navigate = useNavigate();
  const {
    actions: {
      GetAffiliateList,
      GetAffiliateChart,
      GetAffiliateCard,
      GetEmployerChart,
      GetEmployerCard,
      GetEmployerList,
    },
  } = useAffiliate();

  const query = {
    limit,
    page,
    name,
  };

  let searchdata = searchQuery(query);
  let type = selectDropdown.value;

  useEffect(() => {
    if (selectTab === "Affiliators") {
      GetAffiliateCard(type, (res: any) => {
        setAffiliateCard(res.data?.referral_statis);
      });
    } else if (selectTab === "Organizations") {
      GetEmployerCard(type, (res: any) => {
        setEmployerCard(res.data?.referral_statis);
      });
    }
  }, [selectTab, type]);

  useEffect(() => {
    ListData();
  }, [initialState.name, selectTab, type]);

  const ListData = () => {
    handleTable(searchdata);
  };

  const handleTable = (data: any) => {
    setLoading(true);
    if (selectTab === "Affiliators") {
      GetAffiliateList(type, data, (res: any) => {
        setLoading(false);
        setAffiliateList(res.data?.affiliators);
        setTotal(res?.data?.total);
      });
    } else if (selectTab === "Organizations") {
      GetEmployerList(type, data, (res: any) => {
        setLoading(false);
        setEmployerList(res.data?.employers);
        setTotal(res?.data?.total);
      });
    }
    setSearchParams(searchdata);
  };

  useEffect(() => {
    if (selectTab === "Affiliators") {
      GetAffiliateChart(type, (res: any) => {
        setAffiliateChart(res.data);
      });
    } else if (selectTab === "Organizations") {
      GetEmployerChart(type, (res: any) => {
        setEmployerChart(res.data);
      });
    }
  }, [selectDropdown, selectTab]);

  const handleTab = (tab: any) => {
    setSelectTab(tab);
    setSelectDropdown(dropDownList[0]);
    setInitialState({
      ...initialState,
      page: 1,
    });
    setSearchData('')
  };

  const HandleDropdown = (item: any) => {
    setSelectDropdown(item);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialState({
        ...initialState,
        name: searchData,
        page: 1,
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchData]);

  const handlePageChange = (e: any) => {
    setInitialState({
      ...initialState,
      page: e,
    });
  };

  return (
    <div className="">
      <div className="row-center w-[100%] mb-[18px]">
        <p className="module-name">Affiliate marketing</p>
        <div className="min-w-[120px]">
          <DropDownField
            value={selectDropdown}
            data={dropDownList}
            HandleDropdown={HandleDropdown}
          />
        </div>
      </div>

      <div className="tabname">
        {tablist &&
          tablist.map((tab, t) => (
            <div
              key={t}
              className={`${selectTab === tab ? "select-tab" : "unselect-tab"}`}
              onClick={() => handleTab(tab)}
            >
              <p>{tab}</p>
            </div>
          ))}
      </div>
      <div className="mt-[30px] mb-[24px]">
        <AffiliatedStats
          affiliateCard={
            selectTab === "Affiliators" ? affiliateCard : employerCard
          }
        />
      </div>
      <AffiliatedChart
        affiliateChart={
          selectTab === "Affiliators" ? affiliateChart : employerChart
        }
      />
      <div className="mt-[24px] bg-[#fff] rounded-[6px]">
        <TableHeader
          searchLabel={
            selectTab === "Affiliators"
              ? "Search Affiliators"
              : "Search Organizations"
          }
          onChange={(e: any) => setSearchData(e.target.value)}
          value={searchData}
        />
        <div>
          <table>
            <thead>
              <tr>
                <th className="max-w-100">NAME</th>
                <th className="">SIGNUP</th>
                <th className="">CONVERSION</th>
                <th>CREDITS</th>
                {/* <th>STATUS</th> */}
              </tr>
            </thead>
            {selectTab === "Affiliators" && (
              <>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Spinner size={80} color={"spinred"} />
                      </td>
                    </tr>
                  </tbody>
                ) : !affiliateList?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Nodata />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {affiliateList.map((item: any, i: any) => (
                      <tr key={i}>
                        <td
                          className="max-w-100 pointer"
                          onClick={() => navigate(`/affiliate/${item._id}`)}
                        >
                          <div className="row-centers">
                            <img
                              src={item?.logo_url ?? EmptyProfile}
                              className="mr-[5px] w-[33px] rounded-[50%]"
                            />
                            <span className="ml-5 text-[#333333] truncate-line">
                              {ellipsis(item?.affiliator?.name)}
                            </span>
                          </div>
                        </td>
                        <td>{showContent(item?.signups)}</td>
                        <td>{showContent(item?.subscriptions)}</td>
                        <td>{showContent(formattedCurrency(item.credits))}</td>
                        {/* <td>{showContent(formattedCurrency(item.cost))}</td> */}
                        {/* <td>
                          {showContent(showSubLevel(item?.affiliator?.status))}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            )}
            {selectTab === "Organizations" && (
              <>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Spinner size={80} color={"spinred"} />
                      </td>
                    </tr>
                  </tbody>
                ) : !employerList?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Nodata />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {employerList.map((item: any, i: any) => (
                      <tr key={i}>
                        <td
                          className="max-w-100 pointer"
                          onClick={() => navigate(`/employer/${item._id}`)}
                        >
                          <div className="row-centers">
                            <img
                              src={item?.logo_url ?? EmptyProfile}
                              className="mr-[5px] w-[33px] rounded-[50%]"
                            />
                            <span className="text-[#333333] truncate-line">
                              {item?.employer?.name}
                            </span>
                          </div>
                        </td>
                        <td>{showContent(item?.signups)}</td>
                        <td>{showContent(item.subscriptions)}</td>
                        <td>{showContent(formattedCurrency(item.credits))}</td>
                        {/* <td>
                          {showContent(showSubLevel(item?.employer?.status))}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            )}
          </table>
        </div>
      </div>
      <TablePagination
        current={initialState.page ?? 1}
        length={
          selectTab === "Affiliators"
            ? affiliateList.length
            : employerList.length
        }
        pageSize={10}
        total={total}
        onChange={(e: any) => handlePageChange(e)}
      />
    </div>
  );
};

export default AffliatedList;
