import React, { useEffect, useRef } from "react";
import "./style.css";
import close from "../../Assets/icons/close.svg";

const ModalPopUp = ({ ...props }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    props.setShowPopupModal(false);
    props.setModalType && props.setModalType("");
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // If the clicked target is not inside the dropdown, close it
      if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
        handleClose()
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative">
      <div className="popup-container">
        <div className="modal-pop" ref={dropdownRef} >
          <div className="login-popup-title">
            <div
              className={`${
                props.close && "flex justify-center items-center w-[100%]"
              }`}
            >
              <h2>{props.title}</h2>
            </div>

            {props.close ? (
              ""
            ) : (
              <img
                onClick={handleClose}
                src={close}
                alt=""
                className="cursor-pointer"
              />
            )}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ModalPopUp;
