import LineChart from "../../Components/Chart/LineChart";

const AffiliatedChart = ({ ...props }) => {
  const { affiliateChart } = props;
  return (
    <div className="">
      <div className="flex items-center gap-5">
        <div className="chart">
          <div>
            <p className="module-chart-title">Signup</p>
          </div>
          <div className="mt-[40px]">
            <LineChart
              type="red"
              dashboardChart={affiliateChart.referral_signups}
            />
          </div>
        </div>
        <div className="chart">
          <div>
            <p className="module-chart-title">Sign up conversion</p>
          </div>
          <div className="mt-[40px]">
            <LineChart
              type="green"
              dashboardChart={affiliateChart.referral_subscriptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AffiliatedChart;
