import { PageRouter } from "../../Components/Router/PageRouter";
import { SIDEBAR_PATH } from "../../constants/enum";
import ExitBumps from "./ExitBumps";
import { ExitBumpProvider } from "./context";

export default function ExitLayout() {
  const data = [
    {
      path: SIDEBAR_PATH.exit,
      element: <ExitBumps />,
    },
  ];
  return (
    <ExitBumpProvider>
      <PageRouter data={data} />
    </ExitBumpProvider>
  );
}
