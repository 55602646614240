import React, { useState } from "react";
import "./style.css";
import Logo from "../../Assets/images/Frame.svg";
import InputField from "../../Components/InputField";
import ButtonField from "../../Components/ButtonField";
import {
  SaveCookie,
  ShowError,
  isErrorTxt,
  isInvalidEmail,
  removeSpace,
} from "../../helpers/utils";
import { useAuth } from "./context";
import { AUTHENTICATION } from "../../constants/enum";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [initialState, setinitialState] = useState({
    email_address: "",
    password: "",
  });
  const navigate = useNavigate();

  const {
    actions: { Login },
  } = useAuth();

  const handleLogIn = () => {
    if (isErrorTxt(initialState.email_address)) {
      ShowError("Please enter the email address");
    } else if (isInvalidEmail(initialState.email_address)) {
      ShowError("Please enter valid email address");
    } else if (isErrorTxt(initialState.password)) {
      ShowError("Please enter the password");
    } else {
      setLoading(true);
      const data = {
        email: initialState.email_address,
        password: initialState.password,
      };
      Login(data, (res: any) => {
        if (res.success) {
          SaveCookie(AUTHENTICATION.admin_token, res?.data?.token ?? "");
          navigate("/dashboard");
          setTimeout(() => {
            window.location.reload();
          }, 500);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  };

  const onChangeInput = (e: any, item: string) => {
    const data: any = { ...initialState };
    let value = removeSpace(e.target.value);
    if (item === "email_address") {
      data[item] = value.toLowerCase();
    } else {
      data[item] = value;
    }
    setinitialState(data);
  };

  const clickEnter = (e: any) => {
    e.key === "Enter" && handleLogIn();
  };
  return (
    <div className="centered-box">
      <div className="content-center mb-[2rem]">
        <img src={Logo} alt="logo" />
      </div>
      <div className="bg-white px-9 py-9">
        <p className="content-center login-title mb-[2rem]">Control Panel</p>
        <div className="mb-20">
          <InputField
            label="Email"
            placeholder="Enter email"
            mandatory="true"
            onChange={(e: any) => onChangeInput(e, "email_address")}
            onkeydown={clickEnter}
          />
        </div>
        <div className="mb-[40px]">
          <InputField
            type="password"
            label="Password"
            placeholder="Enter password"
            mandatory="true"
            onChange={(e: any) => onChangeInput(e, "password")}
            onkeydown={clickEnter}
          />
        </div>
        <div className="">
          <ButtonField
            value="Log In"
            name="primary"
            loading={loading}
            onClick={() => (loading ? "" : handleLogIn())}
            spinSize={25}
            hand
            // SpinColor="#ffffff"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
