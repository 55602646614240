import schedule_calender from "../../Assets/exit_bumps/schedule_calender.svg";
import schedule_call from "../../Assets/exit_bumps/schedule_call.svg";
import too_expensive from "../../Assets/exit_bumps/too_expensive.svg";
import hard_to_use from "../../Assets/exit_bumps/hard_to_use.svg";
import too_busy from "../../Assets/exit_bumps/too_busy.svg";
import more_research from "../../Assets/exit_bumps/more_research.svg";
import missing_features from "../../Assets/exit_bumps/missing_features.svg";
import shutting_down from "../../Assets/exit_bumps/shutting_down.svg";
import another_prod from "../../Assets/exit_bumps/another_prod.svg";
import other_reason from "../../Assets/exit_bumps/other_reason.svg";
export const KEYS = [
  "all",
  "too_expensive",
  "hard_use",
  "too_busy",
  "more_research",
  "missing_features",
  "shut_down",
  "another_product",
  "other",
];

export const CONVERT_KEYS = [
  "too_expensive",
  "hard_use",
  "too_busy",
  "more_research",
  "missing_features",
];

const CONVERTED_COMMENT = [
  "Offered month off",
  "Off in annual subscription",
  "Scheduled a call",
];

export const getConvertedIcon = (id) => {
  switch (id) {
    case KEYS[1]:
      return `${schedule_calender}`;
    case KEYS[2]:
    case KEYS[3]:
    case KEYS[4]:
    case KEYS[5]:
      return `${schedule_call}`;
    default:
      return "";
  }
};

export const getConvertedToolTip = (data) => {
  switch (data.key) {
    case KEYS[1]:
      if (data.is_month_of) return CONVERTED_COMMENT[0];
      else return CONVERTED_COMMENT[1];
    case KEYS[2]:
    case KEYS[3]:
    case KEYS[4]:
    case KEYS[5]:
      return CONVERTED_COMMENT[2];
    default:
      return false;
  }
};

// getting icon to show in table
export const getOptionIcon = (id) => {
  switch (id) {
    case KEYS[1]:
      return `${too_expensive}`;
    case KEYS[2]:
      return `${hard_to_use}`;
    case KEYS[3]:
      return `${too_busy}`;
    case KEYS[4]:
      return `${more_research}`;
    case KEYS[5]:
      return `${missing_features}`;
    case KEYS[6]:
      return `${shutting_down}`;
    case KEYS[7]:
      return `${another_prod}`;
    case KEYS[8]:
      return `${other_reason}`;
    default:
      return "";
  }
};

export const MultiDataList = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Too Expensive",
    value: "too_expensive",
  },
  {
    label: "Hard to use",
    value: "hard_use",
  },
  {
    label: "Too busy right now",
    value: "too_busy",
  },
  {
    label: "I need to do more research",
    value: "more_research",
  },
  {
    label: "It’s missing the features I need",
    value: "missing_features",
  },
  {
    label: "Shutting down the company",
    value: "shut_down",
  },
  {
    label: "Switching to another product",
    value: "another_product",
  },
  {
    label: "Other Reason",
    value: "other",
  },
];