import { Route, Routes, Outlet, Navigate } from "react-router-dom";

type PageRouterProps = {
  data?: any;
};
export const PageRouter = (props: PageRouterProps) => {
  return (
    <div>
      <Routes>
        {props.data &&
          props.data.length > 0 &&
          props.data.map((ele: any, i: any) => {
            return <Route key={i} path={ele.path} element={ele.element} />;
          })}
      </Routes>
    </div>
  );
};
