import React, { useEffect, useRef, useState } from "react";

import brFilter from "../../Assets/icons/brFilter.svg";
import dropd from "../../Assets/icons/dropd.svg";
import searchicon from "../../Assets/icons/search.svg";
import { data } from "./loadData";
import {
  Nodata,
  ShowDate,
  Spinner,
  ellipsis,
  formattedCurrency,
  searchQuery,
  showContent,
  showSubLevel,
} from "../../helpers/utils";
import EmptyProfile from "../../Assets/icons/emptyProfile.svg";
import Pagination from "../../Components/Pagination/Pagination";
import { useOrganization } from "./context";
import { useParams, useSearchParams } from "react-router-dom";
import TablePagination from "../../Components/Pagination/Pagination";

const OrganizationList = ({ ...props }) => {
  const [organizeList, setOrganizeList]: any = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal]: any = useState(null);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    page: 1,
    limit: 10,
    name: "",
  });

  const { page, limit, name } = initialState;
  const {
    actions: { GetOrganization },
  } = useOrganization();

  const queryParameters: any = new URLSearchParams(window.location.search);
  const query = {
    limit,
    page,
    name,
    subType: queryParameters.get("subType") ?? "",
    subPlan: queryParameters.get("subPlan") ?? "",
  };
  let searchdata = searchQuery(query);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    OrganizationList();
  }, [initialState.name, initialState.page]);

  const OrganizationList = () => {
    handleTable(searchdata);
  };

  const handleTable = (data?: any) => {
    setLoading(true);
    GetOrganization(data, (res: any) => {
      setLoading(false);
      setOrganizeList(res.data?.managers);
      setTotal(res?.data?.total);
    });
    setSearchParams(searchdata);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialState({
        ...initialState,
        name: searchData,
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchData]);

  const handlePageChange = (e: any) => {
    setInitialState({
      ...initialState,
      page: e,
    });
  };

  return (
    <div className="">
      <div className="row-center w-[100%] mb-[18px]">
        <p className="module-name">Organizations</p>
        {/* <button className="btn-filter">
          {" "}
          <img src={brFilter} /> Filter
        </button> */}
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th className="relative max-w-200">
                <input
                  ref={inputRef}
                  placeholder="NAME"
                  className="custom-input"
                  value={searchData}
                  onChange={(e: any) => setSearchData(e.target.value)}
                />
                <div className="absolute left-5 top-5">
                  <img
                    onClick={handleFocus}
                    src={searchicon}
                    className="mt-[2px]"
                  />
                </div>
              </th>
              <th className="">
                <div className="row-centers">
                  <span>Signup date</span>
                  {/* <span className="ml-5">
                    <img src={dropd} />
                  </span> */}
                </div>
              </th>
              <th className="">
                <div className="row-centers">
                  <span>Subscription type</span>
                </div>
              </th>
              <th>
                <div className="row-centers">
                  <span>First paid date</span>
                </div>
              </th>
              <th>Subscription level</th>

              <th>Subscription status</th>
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={8} className="spincenter">
                  <Spinner size={80} color={"spinred"} />
                </td>
              </tr>
            </tbody>
          ) : !organizeList.length ? (
            <tbody>
              <tr>
                <td colSpan={8} className="spincenter">
                  <Nodata />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {organizeList && organizeList.length > 0
                ? organizeList.map((item: any, i: any) => (
                    <tr key={i}>
                      <td className="max-w-200">
                        <div className="row-centers">
                          {item.logo_url ? (
                            <img
                              src={item.logo_url}
                              className="mr-[5px] w-[33px] rounded-[50%]"
                            />
                          ) : (
                            <img src={EmptyProfile} className="mr-[5px]" />
                          )}
                          <span className="ml-5 text-[#333333] truncate-line">
                            {ellipsis(item.name)}
                          </span>
                        </div>
                      </td>
                      <td>{showContent(ShowDate(item.createdAt))}</td>
                      <td>
                        {showContent(item.subscription?.metadata?.name ?? "-")}
                      </td>
                      <td>{showContent(ShowDate(item.first_subscription))}</td>
                      <td>
                        {showContent(
                          showSubLevel(
                            item.subscription?.metadata?.type === "health_free"
                              ? "free"
                              : !item.subscription?.metadata?.type
                              ? ""
                              : "paid"
                          )
                        )}
                      </td>
                      <td>
                        {showContent(showSubLevel(item.subscription?.status))}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          )}
        </table>
       {
         (
          <TablePagination
          current={initialState.page ?? 1}
          length={organizeList.length}
          pageSize={10}
          total={total}
          onChange={(e: any) => handlePageChange(e)}
        />
        )
       }
       
      </div>
    </div>
  );
};

export default OrganizationList;
