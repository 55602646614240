import { Navigate } from "react-router-dom";
import LoginPage from "./login";

import { PageRouter } from "../../Components/Router/PageRouter";

import { SIDEBAR_PATH } from "../../constants/enum";
import { AuthProvider } from "./context";

export default function PublicRoute() {
  const data = [
    {
      path: SIDEBAR_PATH.login,
      element: <LoginPage />,
    },

    {
      path: SIDEBAR_PATH.star,
      element: <Navigate to={SIDEBAR_PATH.login} replace />,
    },
  ];

  return (
    <AuthProvider>
      <PageRouter data={data} />
    </AuthProvider>
  );
}
