import { useNavigate } from "react-router-dom";
import greater from "../Assets/icons/greaterthan.svg";
import { formatString } from "../helpers/utils";
const MasterCards = ({ ...props }) => {
  const { item, key } = props;
  const navigate = useNavigate();
  return (
    <div className="cards w-[100%] zoom" key={key}>
      <div className="w-[100%]">
        <p className="card-title">{formatString(item.name)}</p>
        <div className="flex w-[100%]">
          {item.trail !=="" && (
            <>
              <div className="w-[50%]">
                <div
                  onClick={() => navigate(item.pathFree)}
                  className={item.pathFree ? "pointer" : ""}
                >
                  <p className="card-value mb-[10px]">{item.trail ?? 0}</p>
                  <p className="card-name">{"Free"}</p>
                </div>
              </div>

              <span className="paidplan"></span>
            </>
          )}
          <div
            className={`${
              item.trail ? "w-[38%]" : "w-[96%]"
            } flex items-center justify-between`}
          >
            <div
              className={`flex items-center justify-between w-[100%] ${
                item.pathPaid ? "pointer" : ""
              }`}
              onClick={() => navigate(item.pathPaid)}
            >
              <div className="">
                <p className="card-value mb-[10px]">{props.item.paid ?? 0}</p>
                <p className="card-name">{props.item.isPaidLabel ?? "Paid"}</p>
              </div>
            </div>
          </div>
          <div className={`${item.trail ? "w-[2%]" : "w-[2%]"} mt-2 `}>
            <img className="cursor-pointer" src={greater} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MasterCards;
