import MasterCards from "../../Components/masterCartd";

const SubscriptionCard = ({ ...props }) => {
  const { data } = props;
  const statsList = [
    {
      name: "Basics",
      trail: data?.subscription_plans?.health_basics?.free ?? 0,
      paid: data?.subscription_plans?.health_basics?.paid ?? 0,
      pathFree: "/organizations?page=1&limit=10&subPlan=basics&subType=free",
      pathPaid: "/organizations?page=1&limit=10&subPlan=basics&subType=paid",
    },
    {
      name: "Essential",

      // trail: data?.subscription_plans?.essential?.free ?? 0,
      paid: data?.subscription_plans?.essential?.paid ?? 0,
      pathFree: "/organizations?page=1&limit=10&subPlan=essential&subType=free",
      pathPaid: "/organizations?page=1&limit=10&subPlan=essential&subType=paid",
    },
    {
      name: "Advanced",

      // trail: data?.subscription_plans?.advanced?.free ?? 0,
      paid: data?.subscription_plans?.advanced?.paid ?? 0,
      pathFree: "/organizations?page=1&limit=10&subPlan=advanced&subType=free",
      pathPaid: "/organizations?page=1&limit=10&subPlan=advanced&subType=paid",
    },
  ];
  return (
    <div>
      <div className="mb-[18px]">
        <p className="module-secondary-name">Subscription plan</p>
      </div>

      <div className="flex items-center gap-5">
        {statsList &&
          statsList.map((item, i) => <MasterCards item={item} key={i} />)}
      </div>
    </div>
  );
};
export default SubscriptionCard;
