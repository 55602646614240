import { Navigate } from "react-router-dom";
import { PageRouter } from "../../Components/Router/PageRouter";
import { SIDEBAR_PATH } from "../../constants/enum";
import DashboardPage from "./dashboard";
import { DashboardProvider } from "./context";

export default function DashboardLayout() {
  const module = <Navigate to={SIDEBAR_PATH.dashboard} replace />;

  const data = [
    {
      path: SIDEBAR_PATH.dashboard,
      element: <DashboardPage />,
    },
    {
      path: SIDEBAR_PATH.slash,
      element: module,
    },
    {
      path: SIDEBAR_PATH.login,
      element: module,
    },
  ];
  return (
    <DashboardProvider>
      <PageRouter data={data} />
    </DashboardProvider>
  );
}
