import "./style.css";
import ButtonField from "../../Components/ButtonField";
import AntdModal from "../../Components/Modal/antdModal";

const ModalDeactivate = ({ ...props }) => {
  const handleClose = () => {
    props.setShowPopupModal(false);
  };

  return (
    <AntdModal
      title=""
      isModalOpen={props.isOpen}
      setShowPopupModal={props.setShowPopupModal}
      closable={false}
    >
      <div className="">
        <div className="logout mt-[20px]">
          <div className="login-popup-title">
            <div className={`${"flex justify-center items-center w-[100%] mb-[25px]"}`}>
              <h2>Deactivate</h2>
            </div>
          </div>
          <p>
            {" "}
            Are you sure you want to deactivate this coupon ? Please Confirm !
          </p>
          <div className="w-[200px] m-auto">
            <div className="flex gap-5 mt-[25px]">
              <ButtonField value="No" name="primary" onClick={handleClose} />
              <ButtonField
                value="Yes"
                name="secondary"
                onClick={() => (props.loading ? "" : props.handleSubmit(false))}
                loading={props.loading}
                spincolor="red"
              />
            </div>
          </div>
        </div>
      </div>
    </AntdModal>
  );
};

export default ModalDeactivate;
