import { useState } from "react";
import DropDownField from "../../Components/dropdown/dropdownField";
import LineChart from "../../Components/Chart/LineChart";
import { useDashboard } from "./context";
import { searchQuery } from "../../helpers/utils";

const TrendingChart = ({ ...props }) => {
  const { dashboardChart } = props;
  const dropDownList = [
    {
      label: "This month",
      value: "monthly",
    },
    {
      label: "This year",
      value: "yearly",
    },
  ];
  const [selectDropdown, setSelectDropdown]: any = useState(dropDownList[0]);

  const HandleDropdown = (item: any) => {
    setSelectDropdown(item);
    props.setChartStatus(item);
  };

  return (
    <div className="">
      <div className="mb-[18px] row-center">
        <p className="module-secondary-name">Trending chart</p>
        <div className="min-w-[120px]">
          <DropDownField
            data={dropDownList}
            value={selectDropdown}
            HandleDropdown={HandleDropdown}
          />
        </div>
      </div>
      <div className="flex items-center overflow-hidden w-[100%] gap-5">
        <div className="chart">
          <div>
            <p className="module-chart-title">Signup</p>
          </div>
          <div className="mt-[40px] overflow-hidden">
            <LineChart type="red" dashboardChart={dashboardChart.signup} />
          </div>
        </div>
        <div className="chart">
          <div>
            <p className="module-chart-title">Subscription</p>
          </div>
          <div className="mt-[40px] overflow-hidden">
            <LineChart
              type="green"
              dashboardChart={dashboardChart.subscription}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TrendingChart;
