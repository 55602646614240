import React, { useState } from "react";

import PrivateRouter from "./privateRouter";
import PublicRouter from "./pulicRouter";
import { cookieLoad, GetToken } from "../../helpers/utils";
import { AUTHENTICATION } from "../../constants/enum";
const Mainrouter = () => {
  return GetToken(AUTHENTICATION.admin_token) ? <PrivateRouter /> : <PublicRouter />
};

export default Mainrouter;
