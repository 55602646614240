import { Tooltip } from "antd";
import { capitalize } from "../../helpers/utils";

const ToolTip = ({ ...props }) => {
  return (
    <Tooltip placement="rightTop" title={capitalize(props.name)}>
      {props.children}
    </Tooltip>
  );
};

export default ToolTip;
