import { PageRouter } from "../../Components/Router/PageRouter";
import { SIDEBAR_PATH } from "../../constants/enum";
import DiscountCode from "./DiscountCode";
import { DiscountProvider } from "./context";

export default function DiscountLayout() {
  const data = [
    {
      path: SIDEBAR_PATH.discount,
      element: <DiscountCode />,
    },
  ];
  return (
    <DiscountProvider>
      <PageRouter data={data} />
    </DiscountProvider>
  );
}
