import DashboardStats from "./dashboardStats";
import TrendingChart from "./Trendingchart";
import SubscriptionCard from "./SubscriptionPlan";
import IndividualAppCard from "./IndividualApps";
import { useDashboard } from "./context";
import { useEffect, useState } from "react";
import { searchQuery } from "../../helpers/utils";
import DashboardShimmer from "./dashboardShimmer";

const DashboardPage = () => {
  const [dashboardCards, setDashboardCards] = useState("");
  const [dashboardChart, setDashboardChart] = useState("");
  const [chartStatus, setChartStatus] = useState("monthly");
  const [status, setStatus] = useState("");
  const {
    actions: { GetDashboard, GetDashboardChart },
  } = useDashboard();

  const query = {
    type: chartStatus ?? "monthly",
    subscription: status ?? "",
  };
  let searchdata = searchQuery(query);

  useEffect(() => {
    GetDashboard((res: any) => {
      setDashboardCards(res.data);
    });
  }, []);

  useEffect(() => {
    GetDashboardChart(searchdata, (res: any) => {
      setDashboardChart(res.data);
    });
  }, [chartStatus]);

  const ChartStatus = (val: any) => {
    setChartStatus(val.value);
  };

  return (
    <>
      {!dashboardCards || !dashboardChart ? (
        <DashboardShimmer />
      ) : (
        <div className="page-scroll-y">
          <div className="mb-[24px]">
            <p className="module-name mb-20">Dashboard</p>
            <DashboardStats data={dashboardCards} />
          </div>
          <div className="mb-[24px]">
            <TrendingChart
              setChartStatus={ChartStatus}
              dashboardChart={dashboardChart}
            />
          </div>
          <div className="mb-[24px]">
            <SubscriptionCard data={dashboardCards} />
          </div>
          <div className="">
            <IndividualAppCard data={dashboardCards} />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardPage;
