import { useNavigate } from "react-router-dom";
import { formatString } from "../../helpers/utils";
import greater from "../../Assets/icons/greaterthan.svg";

const IndividualAppCard = ({ ...props }) => {
  const { data } = props;
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="mb-[18px]">
        <p className="module-secondary-name">Individual apps</p>
      </div>

      <div className="flex items-center overflow-x-scroll gap-5">
        {data.individual_apps &&
          data.individual_apps.map((item: any, i: any) => (
            <div className="cards w-[100%] zoom" key={i}>
              <div className="w-[100%]">
                <p className="card-title">{formatString(item.name)}</p>
                <div className="flex w-[100%]">
                  <div className="w-[50%]">
                    <div className={""}>
                      <p className="card-value mb-[10px]">{item.trial ?? 0}</p>
                      <p className="card-name">Trail</p>
                    </div>
                  </div>
                  <span className="paidplan"></span>
                  <div className="w-[38%] ">
                    <div
                      className={`flex items-center justify-between w-[100%]`}
                    >
                      <div className="">
                        <p className="card-value mb-[10px]">
                          {item?.paid ?? 0}
                        </p>
                        <p className="card-name">
                          {props.item?.isPaidLabel ?? "Paid"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[2%] mt-2">
                    <img className="cursor-pointer" src={greater} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default IndividualAppCard;
