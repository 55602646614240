import { useReducer } from "react";
import { container } from "../../userContext/userContext";
import { APIService } from "../../constants/request";
import { buildQueryString, ShowError, ShowSuccess } from "../../helpers/utils";
// import { useToast } from "../../components/toast";

export type StateType = {
  supports: any;
};

const initialState: StateType = {
  supports: [],
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    // case "GET_LOGIN":
    //   return { ...state, login: payload };
    default:
      return state;
  }
};
export const { useContext: useExitBump, Provider: ExitBumpProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });

    const GetExitBumpList = (params: any, callback?: (data: any) => void) => {
      let param = params ? buildQueryString(params, 1) : "";
      APIService.get(`/exitbump${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };
    const GetExitBumpChart = (param: any, callback?: (data: any) => void) => {
      APIService.get(`/exitbump/graph?type=${param}`)
        .then((response) => {
          callback && callback(response);
        })
        .catch((error) => {});
    };
    const UpdateStatus = (
      id: any,
      data: any,
      callback?: (data: any) => void
    ) => {
      APIService.patch(`/exitbump/${id}`, data)
        .then((response) => {
          ShowSuccess(response.message);
          callback && callback(response);
        })
        .catch((error) => {
          callback && callback(error.message);
          ShowError(error.message);
        });
    };
    return {
      state,
      actions: { GetExitBumpList, GetExitBumpChart,UpdateStatus },
    };
  });
