export enum SIDEBAR_PATH {
  login = "/login",
  star = "*",
  slash = "/",
  dashboard = "/dashboard",
  organizations = "/organizations",
  subscription = "/subscription",
  affiliate = "/affiliate",
  employer = "/employer",
  affiliateDetail = "/affiliate/:id",
  employerDetail = "/employer/:id",
  affiliateProfile = "/affiliate/profile/:id",
  employerProfile = "/employer/profile/:id",
  exit = "/exit",
  discount = "/discount",
}
export enum SIDEBAR_KEYS {
  dashboard = "dashboard",
  organizations = "organizations",
  subscription = "subscription",
  affiliate = "affiliate",
  affiliate_marketing = "affiliate marketing",

  exit = "exit",
  exit_bumps = "exit bumps",

  discount = "discount",
  discount_codes = "discount codes",
}
export enum TEXT_COLOR {
  primary = "#4566B0",
  green = "text-[#009C22]",
  yellow = "text-[#E4BA20]",
  orange = "text-[#FF6C22]",
  blue = "text-[#0F91D2]",
  red = "text-[#F14C52]",
}
export enum BG_COLOR {
  bg_green = "bg-[#009C220F]",
  bg_yellow = "bg-[#DAAF1226]",
  bg_orange = "bg-[#fceee6]",
  bg_blue = "bg-[#E5EEFF]",
  bg_dark_blue = "bg-[#4566B0]",
  bg_light_blue = "bg-[#0F91D20F]",
  bg_red = "bg-[#F14C520F]",
}
export enum MESSAGES {
  password = "Password minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character",
}
export enum AUTHENTICATION {
  admin_token = "admin_token",
  forgot_password_token = "forgot_password_token",
  page_number = "page_number",
}
