import { useEffect, useState } from "react";
import {
  Nodata,
  ShowDate,
  Spinner,
  ellipsis,
  formattedCurrency,
  searchQuery,
  showContent,
  showSubLevel,
} from "../../helpers/utils";
import "./style.css";
import AffiliatedStats from "./AffiliatedStats";
import { data } from "./loadData";
import TableHeader from "../../Components/tableHeader";
import EmptyProfile from "../../Assets/icons/emptyProfile.svg";
import ButtonField from "../../Components/ButtonField";
import Breadcrumb from "../../Components/breadCrump";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAffiliate } from "./context";
import TablePagination from "../../Components/Pagination/Pagination";

const EmployerDetail = () => {
  const [selectTab, setSelectTab] = useState("Referred Users");
  const tablist = ["Referred Users", "Credit History"];
  // const tablist = ["Referred Users"];

  const [employerJoinCard, setEmployerJoinCard]: any = useState("");
  const [employerDetail, setEmployerDetail]: any = useState("");
  const [employerJoinList, setEmployerJoinList]: any = useState([]);
  const [employerCreditList, setEmployerCreditList]: any = useState([]);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal]: any = useState(null);

  const [initialState, setInitialState] = useState({
    page: 1,
    limit: 10,
    name: "",
  });
  const { page, limit, name } = initialState;
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    actions: {
      GetEmployerJoinCard,
      GetEmployerJoinDetail,
      GetEmployerJoinList,
      GetEmployerCreditList,
    },
  } = useAffiliate();

  const handleTab = (tab: any) => {
    setSelectTab(tab);
    setInitialState({
      ...initialState,
      name: "",
      page: 1,
    });
    setSearchData("");
  };

  const query = {
    limit,
    page,
    name,
  };

  let searchdata = searchQuery(query);

  useEffect(() => {
    if (selectTab === "Referred Users") {
      GetEmployerJoinCard(id, (res: any) => {
        setEmployerJoinCard(res.data?.referral_statis);
      });
      GetEmployerJoinDetail(id, (res: any) => {
        setEmployerDetail(res.data?.employer);
      });
    }
  }, []);

  useEffect(() => {
    ListData();
  }, [name, page, selectTab]);

  const ListData = () => {
    handleTable(searchdata);
  };

  const handleTable = (data: any) => {
    if (selectTab === "Referred Users") {
      setLoading(true);
      GetEmployerJoinList(id, data, (res: any) => {
        setLoading(false);
        setEmployerJoinList(res.data?.referral_employers);
        setTotal(res?.data?.total);
      });
    } else if (selectTab === "Credit History") {
      setLoading(true);
      GetEmployerCreditList(id, data, (res: any) => {
        setLoading(false);
        setEmployerCreditList(res.data?.referrals);
        setTotal(res?.data?.total);
      });
    }
    setSearchParams(searchdata);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialState({
        ...initialState,
        name: searchData,
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchData]);

  const handlePageChange = (e: any) => {
    setInitialState({
      ...initialState,
      page: e,
    });
  };
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    { name: "Employer", path: `/affiliate` },
    { name: "Details" },
  ];
  const employer = () => {};
  return (
    <div className="">
      <Breadcrumb links={breadcrumbLinks} />
      <div className="mt-[30px] mb-[24px]">
        <AffiliatedStats affiliateCard={employerJoinCard} />
      </div>
      <div className="white-space">
        <div className="flex align-center justify-between mb15">
          {" "}
          <div className="flex align-center">
            <img
              src={employerDetail?.logo_url ?? EmptyProfile}
              className="mr10 w-[25px] h-[25px] rounded-[50%]"
            />

            <span>{employerDetail.name}</span>
          </div>
          <div>
            <ButtonField
              value="View profile"
              classes="fw400 f13"
              name="primary"
              onClick={() => navigate(`/employer/profile/${id}`)}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flexCol w14">
            <span className="clB5B5B5 f14 mb6">Contact name</span>
            <span className="f14 ellipsis">
              {employerDetail.contact_name ?? "-"}
            </span>
          </div>
          <div className="flexCol w14 mr-4">
            <span className="clB5B5B5 f14 mb6">Email id</span>
            <span className="f14 ellipsis">{employerDetail?.email ?? ""}</span>
          </div>
          <div className="flexCol w14">
            <span className="clB5B5B5 f14 mb6">Phone number</span>
            <span className="f14 ellipsis">
              {!employerDetail?.phone ? "-" : employerDetail?.phone}
            </span>
          </div>

          {/* <div className="flexCol w14">
            <span className="clB5B5B5 f14 mb6">Organization</span>
            <span className="f14 ellipsis">{employerDetail.name ?? "-"}</span>
          </div> */}
        </div>
      </div>
      <div className="tabname mt20">
        {tablist &&
          tablist.map((tab, t) => (
            <div
              key={t}
              className={`${selectTab === tab ? "select-tab" : "unselect-tab"}`}
              onClick={() => handleTab(tab)}
            >
              <p>{tab}</p>
            </div>
          ))}
      </div>
      <div className="mt-[24px] bg-[#fff] rounded-[6px]">
        {selectTab === "Referred Users" && (
          <TableHeader
            onChange={(e: any) => setSearchData(e.target.value)}
            value={searchData}
            searchLabel={
              selectTab === "Referred Users"
                ? "Search Organizations"
                : "Search History"
            }
          />
        )}

        <div>
          <table>
            <thead>
              {selectTab === "Referred Users" ? (
                <tr>
                  {/* <th className="max-w-100 fw600">USER NAME</th>
                <th className="fw600">SIGNUP DATE</th>
                <th className="fw600">STATUS</th>
                <th className="fw600">CREDIT STATUS</th>
                <th className="fw600">CREDITS</th> */}
                  <th className="max-w-100 fw600">NAME</th>
                  <th className="fw600">SIGNUP</th>
                  <th className="fw600">PAID SUBSCRIPTION</th>

                  {/* <th className="fw600">CREDITS</th> */}
                </tr>
              ) : (
                <tr>
                  {/* <th className="max-w-100 fw600">USER NAME</th>
                <th className="fw600">SIGNUP DATE</th>
                <th className="fw600">STATUS</th>
                <th className="fw600">CREDIT STATUS</th>
                <th className="fw600">CREDITS</th> */}
                  <th className="max-w-100 fw600">Date</th>
                  <th className="fw600">SIGNUP</th>
                  <th className="fw600">CONVERSION</th>
                  <th className="fw600">CREDITS</th>
                </tr>
              )}
            </thead>
            {selectTab === "Referred Users" && (
              <>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Spinner size={80} color={"spinred"} />
                      </td>
                    </tr>
                  </tbody>
                ) : !employerJoinList?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter h50vh">
                        <Nodata height={80} />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {employerJoinList.map((item: any, i: any) => (
                      <tr key={i}>
                        <td className="max-w-100 pointer">
                          <div className="row-centers">
                            <img
                              src={item?.logo_url ?? EmptyProfile}
                              className="mr-[5px] w-[33px] rounded-[50%]"
                            />
                            <span className="text-[#333333] truncate-line f13 fw500">
                              {item.employer?.name ?? "-"}
                            </span>
                          </div>
                        </td>
                        <td>{showContent(ShowDate(item.createdAt))}</td>
                        {/* <td>
                          {showContent(showSubLevel(item.employer?.status))}
                        </td> */}
                        <td>{showContent(item.subscription?.app)}</td>
                        {/* <td>
                          {showContent(
                            formattedCurrency(item.subscription?.credits)
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            )}
            {selectTab === "Credit History" && (
              <>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter">
                        <Spinner size={80} color={"spinred"} />
                      </td>
                    </tr>
                  </tbody>
                ) : !employerCreditList?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="spincenter h50vh">
                        <Nodata />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {employerCreditList.map((item: any, i: any) => (
                      <tr key={i}>
                        <td
                          className="max-w-100 pointer"
                          onClick={() => navigate(`/affiliate/${item._id}`)}
                        >
                          {showContent(ShowDate(item?.date))}
                        </td>
                        <td>{showContent(item.signups)}</td>
                        <td>{showContent(item?.subscriptions)}</td>
                        <td>{showContent(formattedCurrency(item.credits))}</td>
                        {/* <td>{showContent(formattedCurrency(item.cost))}</td> */}
                        {/* <td>
                          {showContent(showSubLevel(item?.employer?.status))}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </>
            )}
          </table>
        </div>
      </div>
      <TablePagination
        current={initialState.page ?? 1}
        length={
          selectTab === "Referred Users"
            ? employerJoinList.length
            : employerCreditList.length
        }
        pageSize={10}
        total={total}
        onChange={(e: any) => handlePageChange(e)}
      />
    </div>
  );
};

export default EmployerDetail;
