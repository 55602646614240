import { Link } from "react-router-dom";

const Breadcrumb = ({ ...props }) => {
  const { links } = props;
  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {links.map((link: any, index: any) => (
          <li key={index} className="breadcrumb-item">
            {index !== links.length - 1 ? (
              <>
                <Link to={link.path} className="cl757575">
                  {link.name}
                </Link>
                <span className="separator">{" > "}</span>{" "}
              </>
            ) : (
              <span className="cl333333">{link.name}</span> // Last item without a link or separator
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
