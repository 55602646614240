import React, { useEffect, useState } from "react";
import {
  ConvertSecondsToDate,
  GetToken,
  Nodata,
  ParseInt,
  SaveCookie,
  ShowSuccess,
  Spinner,
  getCouponId,
  searchQuery,
  showContent,
} from "../../helpers/utils";
import DropDownField from "../../Components/dropdown/dropdownField";
import TableHeader from "../../Components/tableHeader";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { data, discountData } from "../Affiliate/loadData";
import AffiliatedStats from "../Affiliate/AffiliatedStats";
import ButtonField from "../../Components/ButtonField";
import DiscountDrawer from "./createDiscountDrawer";
import uparrow from "../../Assets/icons/greaterthan.svg";
import "./style.css";
import { useDiscount } from "./context";
import ModalDeactivate from "./ModalDeactivate";
import { AUTHENTICATION } from "../../constants/enum";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const DiscountCode = () => {
  const {
    actions: { GetCouponList, UpdateActiveDeactivate },
  } = useDiscount();
  const [searchParams, setSearchParams] = useSearchParams();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let getPageNumber = GetToken(AUTHENTICATION.page_number);

  const beforePage: any = queryParams.get("end_before");
  const afterPage: any = queryParams.get("start_after");
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [discountIndex, setIndex] = useState("");
  const [pageId, setPageId]: any = useState({
    page: getPageNumber ? ParseInt(getPageNumber) : 1,
    start_after: afterPage ?? "",
    end_before: beforePage ?? "",
  });
  const [bnloading, setbnLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [couponData, setCouponData]: any = useState({});
  const [ShowPopupModal, setShowPopupModal] = useState(false);
  const [isBack, setIsBack] = useState(false);

  const [couponId, setCouponId] = useState<string>("");
  const [selectTab, setSelectTab] = useState("Affiliators");
  const tablist = ["Affiliators", "Employers"];

  const dropDownList = [
    {
      label: "This month",
      value: "tm",
    },
    {
      label: "This year",
      value: "ty",
    },
  ];
  const [selectDropdown, setSelectDropdown] = useState(dropDownList[0]);
  let query = {
    limit: 10,
    start_after: pageId.start_after ?? "",
    end_before: pageId.end_before ?? "",
  };
  if (pageId.page === 1) {
    query["start_after"] = "";
    query["end_before"] = "";
  } else if (pageId.start_after === "" && pageId.end_before === "") {
    SaveCookie(AUTHENTICATION.page_number, 1);
    setPageId({
      start_after: "",
      end_before: "",
      page: 1,
    });
  }
  let searchdata: any = searchQuery(query);

  useEffect(() => {
    handleTable();
  }, [pageId]);

  const handleTable = () => {
    couponList(searchdata);
    setSearchParams(searchdata);
  };

  const couponList = (data: any) => {
    setLoading(true);
    GetCouponList(data, (res: any) => {
      if (res.success) {
        setCouponData(res.data);
        setLoading(false);
      }
    });
  };
  const handleTab = (tab: any) => {
    setSelectTab(tab);
  };

  const HandleDropdown = (item: any) => {
    setSelectDropdown(item);
  };

  const handleDeactivate = (item: any) => {
    setCouponId(item.id);
    if (item.active) {
      setShowPopupModal(true);
    } else {
      setStatusLoading(true);
      handleSubmit(true, item.id);
      setIndex(item.id);
    }
  };

  const handleSubmit = (val: boolean, id?: any) => {
    let data = {
      active: val,
      coupon_id: id ?? couponId,
    };
    setbnLoading(true);
    UpdateActiveDeactivate(data, (res) => {
      setStatusLoading(false);
      if (res.success) {
        ShowSuccess(res.message);
        setShowPopupModal(false);
        setCouponId("");
        setbnLoading(false);
        handleTable();
      } else {
        setbnLoading(false);
      }
    });
  };

  const handleNextPage = () => {
    let res = getCouponId(couponData?.coupons[couponData?.coupons?.length - 1]);
    setPageId({
      start_after: res,
      end_before: "",
      page: pageId.page + 1,
    });
    let count = pageId.page + 1;
    SaveCookie(AUTHENTICATION.page_number, count);
  };

  const handlePreviousPage = () => {
    let res = getCouponId(couponData?.coupons[0]);
    setPageId({
      start_after: "",
      end_before: res,
      page: pageId.page - 1,
    });
    let count = pageId.page - 1;
    SaveCookie(AUTHENTICATION.page_number, count);
  };

  return (
    <>
      {ShowPopupModal && (
        <ModalDeactivate
          setShowPopupModal={setShowPopupModal}
          isOpen={ShowPopupModal}
          apicall={handleTable}
          handleSubmit={handleSubmit}
          loading={bnloading}
        />
      )}
      <div className="">
        <div className="row-center w-[100%] mb-[18px]">
          <p className="module-name">Discount Code Campaigns</p>

          <div className="min-w-[120px]">
            <ButtonField
              value="Create Discount"
              classes="fw500 f13 radius-6"
              name="primary"
              onClick={() => setOpenDrawer(true)}
            />
          </div>
        </div>
        <div className="mt-[30px] mb-[24px]">{/* <AffiliatedStats /> */}</div>
        <div className="mt-[24px] bg-[#fff] rounded-[6px]">
          {/* <TableHeader searchLabel="Search user" /> */}
          <div>
            <table>
              <thead>
                <tr>
                  <th className="min-w-100">DISCOUNT CODE</th>
                  <th className="">NAME OF THE CAMPAIGN</th>
                  <th className="">TYPE</th>
                  <th>START DATE</th>
                  <th>END DATE</th>
                  <th>TIMES USED</th>
                  <th>TOTAL TIMES</th>
                  <th>DISCOUNT</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={9} className="spincenter">
                      <Spinner size={80} color={"spinred"} />
                    </td>
                  </tr>
                ) : couponData && couponData?.coupons?.length > 0 ? (
                  couponData?.coupons.map((item: any, i: any) => (
                    <tr key={i}>
                      <td className="max-w-100">
                        <div className="row-centers">
                          <span className="ml-5 text-[#333333] truncate-line">
                            {item?.code}
                          </span>
                        </div>
                      </td>
                      <td
                        className="max-w-[100px] truncate-line capitalize cursor-pointer"
                        title={item?.coupon?.name ?? ""}
                      >
                        {showContent(item.coupon.name)}
                      </td>
                      <td className="capitalize">
                        {showContent(item.coupon.duration)}
                      </td>
                      <td>
                        {showContent(ConvertSecondsToDate(item.coupon.created))}
                      </td>
                      <td>
                        {showContent(ConvertSecondsToDate(item.expires_at))}
                      </td>
                      <td>{showContent(item.coupon.times_redeemed)}</td>
                      <td>{showContent(item.coupon.max_redemptions)}</td>
                      <td>
                        {showContent(item.coupon.percent_off)}
                        {item.coupon.percent_off && "%"}
                      </td>
                      <td>
                        <div
                          className={`${
                            item.active
                              ? "btn-secondary activate"
                              : "btn-primary activate"
                          } cursor-pointer w-fit`}
                          onClick={() =>
                            statusLoading ? "" : handleDeactivate(item)
                          }
                        >
                          {item.active ? (
                            "Deactivate"
                          ) : (
                            <span className="flex items-center">
                              Activate{" "}
                              {discountIndex === item.id && statusLoading && (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{
                                        fontSize: 15,
                                        color: "#ffffff",
                                      }}
                                      spin
                                    />
                                  }
                                  className="ml-[10px]"
                                />
                              )}
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9} className="spincenter">
                      <Nodata />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="discount-pagination">
          <div>
            <div
              className={`${pageId.page === 1 ? "arrow" : "arrow arrows"}`}
              onClick={() => (pageId.page === 1 ? "" : handlePreviousPage())}
            >
              <img src={uparrow} className="previous_arrow" />
            </div>
            <div
              className={`${couponData.has_more ? "arrow arrows" : "arrow"}`}
              onClick={() => (couponData.has_more ? handleNextPage() : "")}
            >
              <img src={uparrow} />
            </div>
          </div>
        </div>
        <DiscountDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          width="500"
          apicall={handleTable}
        />
      </div>
    </>
  );
};

export default DiscountCode;
