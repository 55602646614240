// import fetch from "isomorphic-unfetch";
import { getResponseBody, handleErrors, handleHeaders } from "./response";
import ENV from "./Env";
import { cookieLoad } from "../helpers/utils";
import { AUTHENTICATION } from "./enum";
/**
 * Promise which returns API response once performing API Request.
 * @param {String} uri The API Route Path.
 * @param {String} apiUrl The Base URL.
 * @param {Object} requestData The Object to fetch API which contains its header/body and other sensitive info.
 */
const _performRequest = (uri, apiUrl, requestData = {}, route) => {
  const url = `${apiUrl}${uri}`;
  return new Promise((resolve, reject) => {
    fetch(url, requestData)
      .then((response) => {
        return handleErrors(response, route);
      })
      .then(handleHeaders)
      .then(getResponseBody)
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => reject(error));
  });
};

const getSession = () => {
  return new Promise((res, rej) => {
    const token = cookieLoad(AUTHENTICATION.admin_token);
    return token ? res(token) : rej(null);
  });
};

/**
 *
 * @param {Object} requestData The Object to fetch API which contains its header/body and other sensitive info.
 */
const _addTokenHeader = (requestData) =>
  getSession()
    .then((token) => {
      requestData.headers.Authorization = `Bearer ${token}`;
      // requestData.headers["x-consumer-username"] = `${token}`;
      return requestData;
    })
    .catch(() => requestData);

export class APIService {
  /**
   * Promise which performs GET Request to the Backend HTTP/S Service.
   * @param {String} uri The API Route.
   * @param {String} apiUrl The Base URL of the API.
   */
  static async get(uri, authToken = undefined, apiUrl = ENV.BASE_URL) {
    let requestData = {
      method: "get",
      referrer: "no-referrer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (authToken) {
      requestData.headers.Authorization = `Bearer ${authToken}`;
    } else {
      requestData = await _addTokenHeader(requestData);
    }
    return _performRequest(uri, apiUrl, requestData);
  }

  /**
   * Promise which performs POST Request to the Backend HTTP/S Service.
   * @param {String} uri The API Route.
   * @param {Object} data The Body of the request.
   * @param {String} apiUrl The Base URL of the API.
   */
  static async post(uri, data, route, apiUrl = ENV.BASE_URL) {
    let requestData = {
      method: "post",
      referrer: "no-referrer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    requestData = await _addTokenHeader(requestData);
    return _performRequest(uri, apiUrl, requestData, route);
  }

  /**
   * Promise which performs POST Request to the Backend HTTP/S Service.
   * @param {String} uri The API Route.
   * @param {Object} data The Body of the request.
   * @param {String} apiUrl The Base URL of the API.
   */
  static async upload(uri, data, apiUrl = ENV.BASE_URL) {
    let requestData = {
      method: "post",
      headers: {},
      body: data,
    };
    requestData = await _addTokenHeader(requestData);
    return _performRequest(uri, apiUrl, requestData);
  }

  /**
   * Promise which performs DELETE Request to the Backend HTTP/S Service.
   * @param {String} uri The API Route.
   * @param {Object} data The body of the request.
   * @param {String} apiUrl The Base URL of the API.
   */
  static async delete(uri, data, apiUrl = ENV.BASE_URL) {
    let requestData = {
      method: "delete",
      referrer: "no-referrer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    requestData = await _addTokenHeader(requestData);
    return _performRequest(uri, apiUrl, requestData);
  }

  /**
   * Promise which performs PUT Request to the Backend HTTP/S Service.
   * @param {String} uri The API Route.
   * @param {Object} data The body of the request.
   * @param {String} apiUrl The Base URL of the API.
   */
  static async put(uri, data, apiUrl = ENV.BASE_URL) {
    let requestData = {
      method: "put",
      referrer: "no-referrer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    requestData = await _addTokenHeader(requestData);
    return _performRequest(uri, apiUrl, requestData);
  }

  /**
   * Promise which performs PATCH Request to the Backend HTTP/S Service.
   * @param {String} uri The API Route.
   * @param {Object} data The body of the request.
   * @param {String} apiUrl The Base URL of the API.
   */
  static async patch(uri, data, apiUrl = ENV.BASE_URL) {
    let requestData = {
      method: "PATCH",
      // referrer: "no-referrer",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    requestData = await _addTokenHeader(requestData);
    return _performRequest(uri, apiUrl, requestData);
  }
}
