import search from "../Assets/icons/searchicon.svg";
const SearchInput = ({ ...props }) => {
  return (
    <div className="relative w-[300px]">
      <input
        placeholder={props.searchLabel}
        // "Search organization"
        className="custom-input-affiliate"
        onChange={props.onChange}
        value={props.value}
      />
      <span className="absolute left-2 top-4">
        <img src={search} className="" />
      </span>
    </div>
  );
};

export default SearchInput;
