import FilterButton from "./filterButton";
import SearchInput from "./searchInput";

const TableHeader = ({ ...props }) => {
  return (
    <div className="row-center w-[100%] affiliate-head">
      <SearchInput
        searchLabel={props.searchLabel}
        onChange={props.onChange}
        value={props.value}
      />
      {/* <FilterButton /> */}
    </div>
  );
};

export default TableHeader;
