import { Pagination } from "antd";

const TablePagination = ({ ...props }) => {
  const { current, pageSize, total, length } = props;

  return (
    <div className="pagination">
      {total > 0 && (
        <>
          <div>{`Showing ${(current - 1) * pageSize + 1} to 
       ${(current - 1) * pageSize + length}
        of ${total} entries`}</div>
          <div>
            <Pagination
              current={current}
              pageSize={pageSize}
              total={total}
              onChange={props.onChange}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default TablePagination;
