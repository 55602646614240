const DashboardShimmer = () => {
  return (
    <div className="w100 h100">
      <div style={{ background: "#f5f5f5" }}>
        <div className="">
          <div className="w-[10%]">
            <div className="line " style={{ height: "20px" }}></div>
          </div>
          <div style={{ display: "flex", marginBottom: "20px" }}>
            {Array(4)
              .fill(null)
              .map((x, o, arr) => {
                return (
                  <div
                    className={
                      "sales_cards shimmer1 flex_sb w-[100%] mr-[15px]"
                    }
                    style={{ height: "5.5rem" }}
                    key={o}
                  >
                    <div className="w-[80%]">
                      <div
                        className="line w-[30%]"
                        style={{ width: Math.random() * 50 + 50 + "%" }}
                      ></div>
                      <div
                        className="line ml-[10px]"
                        style={{ width: Math.random() * 20 + 10 + "%" }}
                      ></div>
                    </div>
                    <div className="line shimround"></div>
                  </div>
                );
              })}
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <div
                className="sales_cards shimmer1 mr-[15px]"
                style={{ height: "20rem", width: "50%" }}
              >
                <div
                  className="line w-[15%] mb-[30px]"
                  style={{
                    height: "0.938rem",
                  }}
                ></div>
                {Array(6)
                  .fill(null)
                  .map((x, i, arr) => {
                    return (
                      <div
                        className="line mb-[30px]"
                        style={{
                          height: "0.625rem",
                          width: Math.random() * 50 + 50 + "%",
                        }}
                        key={i}
                      ></div>
                    );
                  })}
              </div>
              <div
                className="sales_cards shimmer1 mr-[15px]"
                style={{ height: "20rem", width: "50%" }}
              >
                <div
                  className="line w-[15%] mb-[30px]"
                  style={{
                    height: "0.938rem",
                  }}
                ></div>
                {Array(6)
                  .fill(null)
                  .map((x, i, arr) => {
                    return (
                      <div
                        className="line mb-[30px]"
                        style={{
                          height: "0.625rem",
                          width: Math.random() * 50 + 50 + "%",
                        }}
                        key={i}
                      ></div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="w-[15%] flex">
            <div
              className="line "
              style={{ height: "20px", marginRight: "5px", width: "150px" }}
            ></div>
            <div
              className="line "
              style={{ height: "20px", width: "100px" }}
            ></div>
          </div>
          <div style={{ display: "flex" }}>
            {Array(3)
              .fill(null)
              .map((x, o, arr) => {
                return (
                  <div
                    className={
                      "sales_cards shimmer1 flex_sb w-[100%] mr-[15px]"
                    }
                    style={{ height: "6.5rem" }}
                    key={o}
                  >
                    <div className="w-[80%]">
                      <div
                        className="line w-[30%]"
                        style={{ width: Math.random() * 50 + 50 + "%" }}
                      ></div>
                      <div
                        className="line ml-[10px]"
                        style={{ width: Math.random() * 20 + 10 + "%" }}
                      ></div>
                    </div>
                    <div className="line shimround"></div>
                  </div>
                );
              })}
          </div>
          <div className="w-[10%]">
            <div className="line " style={{ height: "20px" }}></div>
          </div>
          <div style={{ display: "flex" }}>
            {Array(3)
              .fill(null)
              .map((x, o, arr) => {
                return (
                  <div
                    className={
                      "sales_cards shimmer1 flex_sb w-[100%] mr-[15px]"
                    }
                    style={{ height: "6.5rem" }}
                    key={o}
                  >
                    <div className="w-[80%]">
                      <div
                        className="line w-[30%]"
                        style={{ width: Math.random() * 50 + 50 + "%" }}
                      ></div>
                      <div
                        className="line ml-[10px]"
                        style={{ width: Math.random() * 20 + 10 + "%" }}
                      ></div>
                    </div>
                    <div className="line shimround"></div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardShimmer;
