import moment, { Moment } from "moment";
import { message, notification, Popover, Spin } from "antd";
import cookie from "react-cookies";
import { LoadingOutlined } from "@ant-design/icons";
import noData from "../Assets/images/no-document.svg";

import { AUTHENTICATION, BG_COLOR, TEXT_COLOR } from "../constants/enum";
const { bg_green, bg_yellow, bg_orange, bg_blue, bg_red, bg_light_blue } =
  BG_COLOR;
const { green, yellow, orange, blue, red } = TEXT_COLOR;

export const statusStyle = {
  status: "text-[13px] font-[500] py-[8px] px-[12px] rounded-[4px]",
};
export const isErrorTxt = (text: any) => {
  return (
    text === "" ||
    text === undefined ||
    text === null ||
    text === "Invalid date"
  );
};

export const Millis = (date: Moment | null) => {
  const nowInMillis: any = moment(date?.toString()).unix();
  const endOfToday: any = moment().endOf("day").unix();

  if (date && date.isSame(moment(), "day")) {
    return Math.floor(endOfToday);
  } else {
    return Math.floor(nowInMillis);
  }
};

export const GetToken = (tokenname: any) => {
  return cookie.load(tokenname);
};

export const showContent = (text: any) => {
  return isErrorTxt(text) ? "-" : text;
};

export const ShowDate = (date: any) => {
  let result = date ? moment(date).format("MMM DD,YYYY") : "-";
  return result;
};

export const ConvertSecondsToDate = (sec: any) => {
  const date = moment.unix(sec);
  return ShowDate(date);
};
export const Element = (style: any, status: any, item: any) => {
  return (
    <span className={`${item ? item : statusStyle.status} ${style}`}>
      {status}
    </span>
  );
};
export let capitalize = (string: any) => {
  if (!string) return "";
  let arr = string.split("_");
  arr[0] = arr[0][0].toUpperCase() + arr[0].slice(1);
  return arr.join(" ");
};

export const showSubLevel = (item: any) => {
  const checkItem = ["paid", "active", "Call schedule"];
  switch (true) {
    case item === "Call schedule":
      return Element(`${bg_light_blue} ${blue}`, capitalize(item), "");
    case checkItem.includes(item):
      return Element(`${bg_green} ${green}`, capitalize(item), "");
    case item === "free":
      return Element(`${bg_red} ${red}`, capitalize(item), "");
  }
};

export const formattedCurrency = (money: number) => {
  return money
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0, // No decimals
        maximumFractionDigits: 2,
      }).format(money)
    : `$${0}`;
};
export const containsAlphabet = (str: string) => {
  return /[a-zA-Z]/.test(str);
};
export const passwordValidation = (value: any) => {
  return !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#:;])[A-Za-z\d@$!%*?&#:;]{6,}$/.test(
    value
  );
};

export const isInvalidEmail = (email: any) => {
  return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
export const ShowError = (value: any) => {
  return message?.error(value);
};
export const ShowSuccess = (value: any) => {
  message.success(value);
};
export const removeSpace = (value: any) => {
  return value && value.trim();
};

export const ParseInt = (value: any) => {
  return parseInt(value);
};
export const ellipsis = (value: any) => {
  return value;
};
export const cookieLoad = (token: any) => {
  return cookie.load(token);
};
export const SaveCookie = (name: any, token: any, expires = 604800) => {
  return cookie.save(name, token, {
    maxAge: expires,
    path: "/",
  });
};

export const RemoveCookie = (token: any) => {
  return cookie.remove(token, {
    path: "/",
  });
};
export const RemoveAllCookie = () => {
  RemoveCookie(AUTHENTICATION.admin_token);
  RemoveCookie(AUTHENTICATION.page_number);
};
export const Reload = () => {
  return window.location.reload();
};
export const NameAndNumberAllowed = (val: any) => {
  return /^[a-zA-Z0-9\s]+$/.test(val);
};
export const numberOnly = (val: any) => {
  return /^\d*$/.test(val);
};
export const NumberRex = (value: any) => {
  return value?.match(/^[0-9]+$/) || value === "";
};
export const decimalNumeric = (value: any) => {
  return value?.match(/^[0-9.]+$/) || value === "";
};
export const getCouponId = (obj: any) => {
  return obj && obj.id;
};
export const Spinner = ({ ...props }) => {
  const { size, color, tip } = props;
  return (
    <div className="flexCol">
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: size, color: color ? "#F14C52" : "#ffffff" }}
            spin
          />
        }
        className={`${!tip && "mb-[20px]"}`}
      />
      {!tip && (
        <span className="f15 flex justify-center">
          Please wait while we load your data
        </span>
      )}
    </div>
  );
};
export const Nodata = ({ ...props }) => {
  return (
    <div className="flexCol">
      <img src={noData} style={{ height: "110px" }} />
      <span className="f15 flex justify-center">No data found</span>
    </div>
  );
};

export const buildQueryString = (params: any, num: any, question?: any) => {
  let newParams = null;
  if (params) {
    newParams = [];
    for (let key in params) {
      var value = params[key];
      let model = isErrorTxt(num) ? 1 : num;
      if (paramKey(value, model)) {
        delete params[key];
      } else {
        newParams.push(`${key}=${params[key]}`);
      }
    }
    return question
      ? (newParams = "&" + newParams.join("&"))
      : (newParams = "?" + newParams.join("&"));
    // return (newParams = "?" + newParams.join("&"));
  }
  return newParams;
};

const paramKey = (value: any, number: any) => {
  switch (number) {
    case 1:
      return checkParamkey(value) || value === 0;
    case 2:
      return checkParamkey(value);
  }
};

const checkParamkey = (value: any) => {
  let age = `undefined,undefined`;
  return (
    value === age ||
    value === "0,100" ||
    value === "" ||
    value === null ||
    value === undefined ||
    value === "-" ||
    value === "Invalid date"
  );
};

export const searchQuery = (params: any, num?: any) => {
  let newParams = null;
  if (params) {
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var value = params[key];
        let model = isErrorTxt(num) ? 1 : num;

        if (paramKey(value, model)) {
          delete params[key];
        } else {
          newParams = params;
        }
      }
    }
  }
  return newParams;
};
export const firstCapitalized = (text: any) => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatString = (str: string): string => {
  let formattedStr = str?.replace(/_/g, " ");
  formattedStr = formattedStr
    ?.split(" ")
    .map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1))
    .join(" ");
  return formattedStr;
};
export const MantotarySymbol = () => {
  return <span className="text-primary">*</span>;
};
export const showStatus = (item: any) => {
  // const checkItem = ["paid", "active", "Call schedule"];
  switch (true) {
    case item === "call_schedule":
      return Element(`${bg_light_blue} ${blue}`, capitalize(item), "");
    case item === "converted":
      return Element(`${bg_green} ${green}`, capitalize(item), "");
    case item === "lost":
      return Element(`${bg_red} ${red}`, capitalize(item), "");
  }
};

export const contentLengthRestrict = (type: any, content: any) => {
  let value = content?.length > 13;
  let length: any = {
    1: content?.slice(0, 10),
    2: content?.slice(0, 12),
    3: content?.slice(0, 20),
    4: content?.slice(0, 36),
  };
  const checkType = (item: any) => {
    return length[item];
  };
  return value ? checkType(type) + "..." : content;
};

export const JoinMethod = (item: any) => {
  let arr: any = [];
  if (item.length > 0) {
    item?.map((val: any, i: any) => {
      return arr.push(val.label);
    });
  } else {
    return "";
  }
  if (arr.length > 0) {
    let plainString = arr.join();
    return contentLengthRestrict(4, plainString);
  }
};
