import React, { useEffect, useState } from "react";
import {
  Nodata,
  ShowDate,
  Spinner,
  ellipsis,
  formattedCurrency,
  searchQuery,
  showContent,
  showStatus,
} from "../../helpers/utils";
import DropDownField from "../../Components/dropdown/dropdownField";
import TableHeader from "../../Components/tableHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BarChart } from "../../Components/Chart/BarChart";
import { useExitBump } from "./context";
import EmptyProfile from "../../Assets/icons/emptyProfile.svg";
import TablePagination from "../../Components/Pagination/Pagination";
import {
  CONVERT_KEYS,
  KEYS,
  MultiDataList,
  getConvertedIcon,
  getConvertedToolTip,
  getOptionIcon,
} from "./loadData";
import PopOver from "../../Components/Popover/PopOver";
import StatusDropDown from "../../Components/dropdown/statusDropdown";
import Multipleselect from "../../Components/dropdown/multipleSelect";

const dropDownList = [
  {
    label: "This month",
    value: "monthly",
  },
  {
    label: "This year",
    value: "yearly",
  },
];
const StatusList = [
  {
    label: "Converted",
    value: "converted",
  },
  {
    label: "Lost",
    value: "lost",
  },
];

const ExitBumps = () => {
  const [selectDropdown, setSelectDropdown]: any = useState(dropDownList[0]);
  const [selectStatus, setSelectStatus]: any = useState({});
  const [multiSelectStatus, setMultiSelectStatus]: any = useState([]);
  const [exitBumpList, setExitBumpList]: any = useState("");
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal]: any = useState(null);
  const [exitBumpChart, setExitBumpChart]: any = useState("");
  const [initialState, setInitialState] = useState({
    page: 1,
    limit: 10,
    keys: "",
    name: "",
  });
  const { page, limit, name, keys } = initialState;
  const navigate = useNavigate();
  const {
    actions: { GetExitBumpList, GetExitBumpChart, UpdateStatus },
  } = useExitBump();

  const query = {
    limit,
    page,
    keys,
    name,
  };
  let type = selectDropdown.value;

  let searchdata = searchQuery(query);
  useEffect(() => {
    GetExitBumpChart(type, (res: any) => {
      setExitBumpChart(res?.data);
    });
  }, [selectDropdown]);

  useEffect(() => {
    exitBump();
    // HandleMultiStatusDropdown(multiSelectStatus);
  }, [page, name, keys]);

  useEffect(() => {
    if (multiSelectStatus && multiSelectStatus) {
      let arr_string: any = [];
      console.log(multiSelectStatus);

      let result = multiSelectStatus.map((obj: any, o: any) => {
        return obj.value !== "all" && arr_string.push(obj.value);
      });
      // if (arr_string.includes("all")) {
      //    arr_string.filter((ob:any) => ob !== 'all');
      // }else{}
      const plainString = arr_string.join();

      setInitialState({
        ...initialState,
        keys: plainString,
        page: 1,
      });
      // let res = arr_string.push(item.value)
      // setMultiSelectStatus(arr_string);
    }
  }, [multiSelectStatus]);

  const exitBump = () => {
    handleTable(searchdata);
  };

  const handleTable = (data: any) => {
    setLoading(true);
    GetExitBumpList(data, (res: any) => {
      setLoading(false);
      setExitBumpList(res.data?.exitbumps);
      setTotal(res?.data?.total);
    });
    setSearchParams(searchdata);
  };

  const HandleDropdown = (item: any) => {
    setSelectDropdown(item);
  };

  const HandleStatusDropdown = (item: any, id: any) => {
    console.log(item);
    if (item.value && id) {
      let data = {
        status: item.value,
      };
      UpdateStatus(id, data, (res) => {
        if (res) {
          exitBump();
        }
      });
    }
    // setSelectStatus(item)
  };
  // const HandleMultiStatusDropdown = (item: any) => {
  //   let arr_string: any = [];
  //   console.log(multiSelectStatus);
  //   if (item) {
  //     let result = item.map((obj: any, o: any) => {
  //       return arr_string.push(obj.value);
  //     });
  //     // let res = arr_string.push(item.value)
  //     setMultiSelectStatus(arr_string);
  //   }
  // };

  console.log(multiSelectStatus, "multiSelectStatus:::");
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialState({
        ...initialState,
        name: searchData,
        page: 1,
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchData]);

  const handlePageChange = (e: any) => {
    setInitialState({
      ...initialState,
      page: e,
    });
  };

  const HandleMultiStatusDropdown = (item: any, i: any) => {
    let res = MultiDataList;

    const foundObject = CheckLabel(multiSelectStatus, "All");
    if (arraysMatch(res, multiSelectStatus) && item.label !== "All") {
      const filteredArray = multiSelectStatus.filter(
        (obj: any) => obj.label !== "All" && obj.label !== item.label
      );
      setMultiSelectStatus(filteredArray);
    } else if (!foundObject && item.label === "All") {
      setMultiSelectStatus(res);
    } else if (foundObject && item.label === "All") {
      setMultiSelectStatus([]);
    } else if (CheckLabel(multiSelectStatus, item.label)) {
      const filteredArray = multiSelectStatus.filter(
        (obj: any) => obj.label !== item.label
      );
      setMultiSelectStatus(filteredArray);
    } else {
      setMultiSelectStatus([item, ...multiSelectStatus]);
    }
  };
  const deepEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const arraysMatch = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((obj1: any, index: any) => deepEqual(obj1, arr2[index]));
  };

  const CheckLabel = (arr: any, label: any) => {
    return arr.find((obj: any) => obj.label === label);
  };
  return (
    <div className="">
      <div className="row-center w-[100%] mb-[18px]">
        <p className="module-name">Exit Bumps</p>
        <div className="min-w-[120px]">
          <DropDownField
            value={selectDropdown}
            data={dropDownList}
            HandleDropdown={HandleDropdown}
          />
        </div>
      </div>
      <div className="white-space">
        <BarChart barChartValue={exitBumpChart} />
      </div>
      <div className="mt-[24px] bg-[#fff] rounded-[6px]">
        <div className="row-center pr-[20px]">
          <TableHeader
            searchLabel={"Search organization"}
            onChange={(e: any) => setSearchData(e.target.value)}
            value={searchData}
          />
          <div className="w-[330px]">
            <Multipleselect
              value={multiSelectStatus}
              setValue={setMultiSelectStatus}
              data={MultiDataList}
              handleClicks={HandleMultiStatusDropdown}
              placeholder="Reason"
            />
          </div>
        </div>

        <div>
          <table>
            <thead>
              <tr>
                <th className="max-w-100">NAME</th>
                <th className="">DATE</th>
                <th className="">OPTION SELECTED</th>
                <th>CONVERTED THROUGH</th>
                <th>CONVERTED</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="spincenter">
                    <Spinner size={80} color={"spinred"} />
                  </td>
                </tr>
              </tbody>
            ) : !exitBumpList?.length ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="spincenter">
                    <Nodata />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {exitBumpList.map((item: any, i: any) => (
                  <tr key={i}>
                    <td
                      className="max-w-100 pointer"
                      // onClick={() => navigate(`/affiliate/${item._id}`)}
                    >
                      <div className="row-centers">
                        {item.logo_url ? (
                          <img
                            src={item.logo_url}
                            className="mr-[5px] w-[33px] rounded-[50%]"
                          />
                        ) : (
                          <img src={EmptyProfile} className="mr-[5px]" />
                        )}
                        <span className="ml-5 text-[#333333] truncate-line">
                          {ellipsis(item?.company_name)}
                        </span>
                      </div>
                    </td>
                    <td>{showContent(ShowDate(item.updatedAt))}</td>
                    <td>
                      {KEYS.includes(item.key) ? (
                        <PopOver content={item.key}>
                          {<img src={getOptionIcon(item?.key ?? "")} alt="" />}
                        </PopOver>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {CONVERT_KEYS.includes(item.key) ? (
                        <PopOver content={getConvertedToolTip(item)}>
                          <img src={getConvertedIcon(item?.key ?? "")} alt="" />
                        </PopOver>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {item.status === "schedule" ? (
                        <div className="max-w-[150px]">
                          <StatusDropDown
                            value={selectStatus}
                            data={StatusList}
                            HandleDropdown={(val:any) =>
                              HandleStatusDropdown(val, item._id)
                            }
                          />
                        </div>
                      ) : (
                        showContent(showStatus(item.status))
                      )}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <TablePagination
        current={initialState.page ?? 1}
        length={exitBumpList.length}
        pageSize={10}
        total={total}
        onChange={(e: any) => handlePageChange(e)}
      />
    </div>
  );
};

export default ExitBumps;
